<template>
 <button
  id="btn"
  :disabled="this.mode === 'disabled'"
  @click="handleClick"
  :class="this.mode"
 >
  {{ title }}
 </button>
</template>

<script>
export default {
 props: ["title", "mode", "w"],
 computed: {
  width() {
   if (this.w) {
    return this.w;
   }
   return "fit-content";
  },
  isDisabled() {
   if (this.disabled) {
    return this.disabled;
   }
   return false;
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
.flat {
 color: black;
 border: 2px solid var.$brand;
 background-color: transparent;
 padding: 14px 38px;
}
.flat:hover {
 transition: 200ms;
 background-color: #29abe325;
}
.delete {
 color: white;
 border-style: hidden;
 background-color: var.$red;
}
.delete:hover {
 transition: 200ms;
 background-color: #b92323;
}
.full {
 border-style: hidden;
 color: white;
 background-color: var.$brand;
 transition: all 200ms;
}
.full:hover {
 background-color: #269cce;
}
.disabled {
 background-color: var.$grey;
 border-style: hidden;
}
#btn {
 width: v-bind(width);

 margin: 0px;
 cursor: pointer;
 padding: 16px 40px;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 border-radius: 8px;
}
</style>
