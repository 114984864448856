import { BASE_URL } from ".";
const organizationModule = {
 state: {
  OrgLoading: false,
  searchOptions: {},
  orgTypes: [],
  orgList: [],
  localpagesData: {
   currentPage: 1,
   perPage: 5,
  },
 },
 getters: {
  getOrgLoading(state) {
   return state.OrgLoading;
  },
  getOrgPagesData(state) {
   return state.pagesData;
  },
  getOrgTypes(state) {
   return state.orgTypes;
  },
  getOrgList(state) {
   return state.orgList;
  },
 },
 actions: {
  setOrgPage(context, payload) {
   context.commit("setOrgPage", payload);
   if (payload.fetch) {
    context.dispatch("getOrgList");
   }
  },
  linkUser(context, payload) {
   fetch(`${BASE_URL}/api/organization/adduser`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "error") {
      context.commit("setError", data.messege);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  setOrgSearchOptions(context, payload) {
   context.commit("setOrgSearchOptions", payload);
   if (payload.fetch) {
    context.dispatch("getOrgList");
   }
  },
  saveOrg(context, payload) {
   fetch(`${BASE_URL}/api/organization/save`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.dispatch("getOrgList");
     } else {
      context.commit("setError", data.messege);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  deleteOrg(context, payload) {
   fetch(`${BASE_URL}/api/organization/delete`, {
    method: "POST",

    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     organization_id: payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.dispatch("getOrgList");
     } else {
      context.commit("setError", data.messege);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  createOrg(context, payload) {
   fetch(`${BASE_URL}/api/organization/create`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.dispatch("getOrgList");
     } else {
      context.commit("setError", data.messege);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  getOrgTypes(context) {
   fetch(`${BASE_URL}/api/organization/listorgtypes`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.commit("setOrgTypes", data.payload);
     } else {
      context.commit("setError", data.messege);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  getOrgList(context, payload) {
   context.state.OrgLoading = true;
   fetch(`${BASE_URL}/api/organization/list?lazy=false`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: payload
     ? JSON.stringify({ ...payload })
     : JSON.stringify({
        ...context.state.searchOptions,
        page: context.state.localpagesData.currentPage,
        numonpage: context.state.localpagesData.perPage,
       }),
   })
    .then((response) => response.json())
    .then((data) => {
     context.state.OrgLoading = false;
     if (data.status === "success") {
      context.commit("setOrgList", data.payload);
     } else {
      context.commit("setOrgList", null);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
 },
 mutations: {
  setOrgPage(state, payload) {
   state.localpagesData.currentPage = payload.currentPage;
   state.localpagesData.perPage = payload.perPage;
  },
  setOrgSearchOptions(state, payload) {
   state.searchOptions = payload;
  },
  setOrgTypes(state, payload) {
   state.orgTypes = payload;
  },
  setOrgList(state, payload) {
   if (payload) {
    state.orgList = payload.results;
    state.pagesData = payload.pager;
    state.pagesData.totalPages = Math.ceil(
     payload.pager.total / payload.pager.numOnPage
    );
   } else {
    state.orgList = [];
   }
  },
 },
};
export default organizationModule;
