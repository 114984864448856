<template>
 <base-popup>
  <div class="login-container">
   <h1>Вход</h1>
   <div class="input">
    <base-input
     label="Логин"
     w="100"
     :value="email"
     @input="this.email = $event.target.value"
    />
   </div>
   <div class="input">
    <base-input
     label="Пароль"
     type="password"
     w="100"
     :value="password"
     @input="this.password = $event.target.value"
    />
   </div>

   <base-button mode="full" title="войти" @click="login" />
  </div>
 </base-popup>
</template>

<script>
import BaseButton from "../UI/BaseButton.vue";
export default {
 components: { BaseButton },
 data() {
  return {
   email: null,
   password: null,
  };
 },

 methods: {
  login() {
   if (this.email && this.password) {
    this.$store.dispatch("login", {
     email: this.email,
     password: this.password,
    });
   }
  },
 },
};
</script>

<style lang="scss" scoped>
h1 {
 margin-bottom: 20px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 font-size: 30px;
}
.input {
 margin-bottom: 20px;
}
.login-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
</style>
