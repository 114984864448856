<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M43.8352 31.9448H45.4118C46.1102 31.9448 46.1569 31.992 46.5168 32.3562L46.5598 32.3996L46.561 32.3984C46.7102 32.5475 47.056 32.8996 47.4036 33.2535L47.5681 33.421C47.9601 33.8198 47.9998 33.8602 47.9998 34.5868V37.6983C47.9998 38.0421 47.8589 38.3548 47.6321 38.5818V38.5843C47.4053 38.8111 47.0917 38.952 46.7464 38.952H43.8355V37.6719H46.72V34.5868C46.72 34.3788 46.7142 34.3728 46.6582 34.316L46.4937 34.1485C46.283 33.9338 46.0703 33.7176 45.6562 33.3035V33.3009L45.607 33.2512C45.5841 33.2281 45.5811 33.2251 45.4121 33.2251H43.8355V31.9451L43.8352 31.9448Z"
  />
  <path
   d="M43.8352 37.6716H45.4118C46.11 37.6716 46.1569 37.7191 46.5166 38.0827L46.5598 38.1262L46.561 38.1249C46.7097 38.2736 47.057 38.6272 47.4061 38.9826L47.5681 39.1473C47.9601 39.5461 47.9998 39.5865 47.9998 40.3131V43.4246C47.9998 43.7684 47.8589 44.0811 47.6321 44.3081V44.3106C47.4053 44.5374 47.0917 44.6783 46.7464 44.6783H43.8355V43.3982H46.72V40.3131C46.72 40.1049 46.7142 40.0991 46.6582 40.0421L46.4962 39.8773C46.2853 39.6626 46.0726 39.4462 45.6562 39.0298V39.0273L45.6067 38.9775C45.5839 38.9544 45.5808 38.9514 45.4118 38.9514H43.8352V37.6714V37.6716Z"
  />
  <path
   d="M20.4029 16H42.1287C42.7746 16 43.3615 16.2639 43.7865 16.6889C44.2114 17.1138 44.4753 17.7007 44.4753 18.3466V45.6534C44.4753 46.2993 44.2114 46.8862 43.7865 47.3111C43.3613 47.7361 42.7746 48 42.1287 48H20.4029C19.757 48 19.1701 47.7361 18.7451 47.3111C18.3202 46.8862 18.0563 46.2993 18.0563 45.6534V18.3466C18.0563 17.7007 18.3202 17.1138 18.7451 16.6889C19.1701 16.2639 19.757 16 20.4029 16ZM42.1287 17.28H20.4029C20.1103 17.28 19.8436 17.4003 19.65 17.5939C19.4563 17.7876 19.3361 18.054 19.3361 18.3468V45.6537C19.3361 45.9462 19.4563 46.2129 19.65 46.4066C19.8436 46.6002 20.1103 46.7205 20.4029 46.7205H42.1287C42.4213 46.7205 42.688 46.6002 42.8816 46.4066C43.0753 46.2129 43.1955 45.9465 43.1955 45.6537V18.3468C43.1955 18.0543 43.0753 17.7876 42.8816 17.5939C42.688 17.4003 42.4213 17.28 42.1287 17.28Z"
  />
  <path d="M20.7085 21.9944H16.6396V20.7144H20.7085V21.9944Z" />
  <path d="M20.7085 26.262H16.6396V24.9819H20.7085V26.262Z" />
  <path d="M20.7085 30.5291H16.6396V29.249H20.7085V30.5291Z" />
  <path d="M20.7085 34.7964H16.6396V33.5164H20.7085V34.7964Z" />
  <path d="M20.7085 39.0637H16.6396V37.7837H20.7085V39.0637Z" />
  <path d="M20.7085 43.3313H16.6396V42.0513H20.7085V43.3313Z" />
  <path d="M23.9775 16.6399V47.3601H22.6975V16.6399H23.9775Z" />
  <path
   d="M31.6834 23.513L32.4611 27.3649L32.4616 27.3647C32.6115 27.9242 32.2743 28.1578 31.883 28.3428C32.16 28.871 32.4978 29.3152 32.8865 29.6889C33.2557 30.0438 33.6738 30.3376 34.1314 30.5812C34.2999 30.1771 34.5159 29.8378 35.0684 29.9802L38.958 30.815C39.1616 30.8587 39.3505 30.9212 39.5285 31.0764C39.7214 31.2444 39.8507 31.4755 39.8851 31.8233C39.9238 32.2138 39.914 32.5679 39.8588 32.8871C39.7988 33.2324 39.6883 33.5396 39.5298 33.8108C38.9996 34.7186 38.1001 35.1227 37.0968 35.233C35.8341 35.3716 34.3956 35.0218 33.44 34.6727C32.0661 34.1709 30.9184 33.4419 29.9937 32.5169C29.0422 31.5649 28.3322 30.4117 27.8596 29.0902C27.5585 28.2484 27.228 26.9611 27.2687 25.7783C27.3003 24.8561 27.555 23.9899 28.2021 23.392C28.5718 23.0504 28.9822 22.8292 29.388 22.7014C29.8699 22.5497 30.3483 22.5313 30.7471 22.6007C30.9531 22.6366 31.1309 22.6886 31.3144 22.841C31.4958 22.9917 31.6198 23.1966 31.6836 23.5128L31.6834 23.513ZM31.1429 27.2778L30.4508 23.8493C30.2481 23.8264 30.0113 23.8448 29.7677 23.9214C29.5304 23.996 29.2883 24.1276 29.0668 24.332C28.7077 24.6637 28.564 25.2115 28.5432 25.8182C28.5093 26.8056 28.7998 27.9214 29.064 28.6603C29.4744 29.808 30.0846 30.8032 30.8983 31.6169C31.6906 32.4095 32.6816 33.0368 33.8745 33.4725C34.6982 33.7734 35.9235 34.0767 36.9612 33.9627C37.5855 33.8942 38.132 33.6659 38.424 33.1656C38.5069 33.0237 38.5654 32.8595 38.5981 32.6719C38.6305 32.4855 38.6393 32.2786 38.6232 32.0498L35.2113 31.3175C35.1699 31.4225 35.1257 31.5262 35.0691 31.6242C34.8389 32.023 34.5382 32.2126 34.0955 31.9803C33.3162 31.6397 32.6098 31.194 32.0008 30.6086C31.3948 30.0259 30.8895 29.3087 30.5106 28.424C30.2539 27.9506 30.4671 27.6447 30.8805 27.4059H30.883C30.9706 27.3554 31.0563 27.3157 31.1424 27.2773L31.1429 27.2778Z"
  />
  <path d="M39.6666 38.6155H27.5062V37.3354H39.6666V38.6155Z" />
  <path d="M39.6666 41.5027H27.5062V40.2229H39.6666V41.5027Z" />
  <path
   d="M16.6399 21.9941C16.9933 21.9941 17.2798 21.7076 17.2798 21.3542C17.2798 21.0008 16.9933 20.7144 16.6399 20.7144C16.2865 20.7144 16 21.0008 16 21.3542C16 21.7076 16.2865 21.9941 16.6399 21.9941Z"
  />
  <path
   d="M20.7085 21.9941C21.0619 21.9941 21.3484 21.7076 21.3484 21.3542C21.3484 21.0008 21.0619 20.7144 20.7085 20.7144C20.3551 20.7144 20.0686 21.0008 20.0686 21.3542C20.0686 21.7076 20.3551 21.9941 20.7085 21.9941Z"
  />
  <path
   d="M16.6399 26.2617C16.9933 26.2617 17.2798 25.9752 17.2798 25.6218C17.2798 25.2684 16.9933 24.9819 16.6399 24.9819C16.2865 24.9819 16 25.2684 16 25.6218C16 25.9752 16.2865 26.2617 16.6399 26.2617Z"
  />
  <path
   d="M20.7085 26.2617C21.0619 26.2617 21.3484 25.9752 21.3484 25.6218C21.3484 25.2684 21.0619 24.9819 20.7085 24.9819C20.3551 24.9819 20.0686 25.2684 20.0686 25.6218C20.0686 25.9752 20.3551 26.2617 20.7085 26.2617Z"
  />
  <path
   d="M16.6399 30.5288C16.9933 30.5288 17.2798 30.2423 17.2798 29.8889C17.2798 29.5355 16.9933 29.249 16.6399 29.249C16.2865 29.249 16 29.5355 16 29.8889C16 30.2423 16.2865 30.5288 16.6399 30.5288Z"
  />
  <path
   d="M20.7085 30.5288C21.0619 30.5288 21.3484 30.2423 21.3484 29.8889C21.3484 29.5355 21.0619 29.249 20.7085 29.249C20.3551 29.249 20.0686 29.5355 20.0686 29.8889C20.0686 30.2423 20.3551 30.5288 20.7085 30.5288Z"
  />
  <path
   d="M16.6399 34.7964C16.9933 34.7964 17.2798 34.5099 17.2798 34.1565C17.2798 33.8031 16.9933 33.5166 16.6399 33.5166C16.2865 33.5166 16 33.8031 16 34.1565C16 34.5099 16.2865 34.7964 16.6399 34.7964Z"
  />
  <path
   d="M20.7085 34.7964C21.0619 34.7964 21.3484 34.5099 21.3484 34.1565C21.3484 33.8031 21.0619 33.5166 20.7085 33.5166C20.3551 33.5166 20.0686 33.8031 20.0686 34.1565C20.0686 34.5099 20.3551 34.7964 20.7085 34.7964Z"
  />
  <path
   d="M16.6399 39.0635C16.9933 39.0635 17.2798 38.777 17.2798 38.4236C17.2798 38.0702 16.9933 37.7837 16.6399 37.7837C16.2865 37.7837 16 38.0702 16 38.4236C16 38.777 16.2865 39.0635 16.6399 39.0635Z"
  />
  <path
   d="M20.7085 39.0635C21.0619 39.0635 21.3484 38.777 21.3484 38.4236C21.3484 38.0702 21.0619 37.7837 20.7085 37.7837C20.3551 37.7837 20.0686 38.0702 20.0686 38.4236C20.0686 38.777 20.3551 39.0635 20.7085 39.0635Z"
  />
  <path
   d="M16.6399 43.3311C16.9933 43.3311 17.2798 43.0446 17.2798 42.6912C17.2798 42.3378 16.9933 42.0513 16.6399 42.0513C16.2865 42.0513 16 42.3378 16 42.6912C16 43.0446 16.2865 43.3311 16.6399 43.3311Z"
  />
  <path
   d="M20.7085 43.3311C21.0619 43.3311 21.3484 43.0446 21.3484 42.6912C21.3484 42.3378 21.0619 42.0513 20.7085 42.0513C20.3551 42.0513 20.0686 42.3378 20.0686 42.6912C20.0686 43.0446 20.3551 43.3311 20.7085 43.3311Z"
  />
  <path
   d="M27.5062 41.5027C27.8596 41.5027 28.1461 41.2162 28.1461 40.8628C28.1461 40.5094 27.8596 40.2229 27.5062 40.2229C27.1528 40.2229 26.8663 40.5094 26.8663 40.8628C26.8663 41.2162 27.1528 41.5027 27.5062 41.5027Z"
  />
  <path
   d="M39.6669 41.5027C40.0203 41.5027 40.3068 41.2162 40.3068 40.8628C40.3068 40.5094 40.0203 40.2229 39.6669 40.2229C39.3135 40.2229 39.027 40.5094 39.027 40.8628C39.027 41.2162 39.3135 41.5027 39.6669 41.5027Z"
  />
  <path
   d="M27.5062 38.6152C27.8596 38.6152 28.1461 38.3287 28.1461 37.9753C28.1461 37.6219 27.8596 37.3354 27.5062 37.3354C27.1528 37.3354 26.8663 37.6219 26.8663 37.9753C26.8663 38.3287 27.1528 38.6152 27.5062 38.6152Z"
  />
  <path
   d="M39.6669 38.6152C40.0203 38.6152 40.3068 38.3287 40.3068 37.9753C40.3068 37.6219 40.0203 37.3354 39.6669 37.3354C39.3135 37.3354 39.027 37.6219 39.027 37.9753C39.027 38.3287 39.3135 38.6152 39.6669 38.6152Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
