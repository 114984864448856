import { BASE_URL } from ".";
const accountModule = {
 state: {
  validPassword: false,
 },
 getters: {
  getPasswordValidity(state) {
   return state.validPassword;
  },
 },
 actions: {
  async saveUser(context, payload) {
   fetch(`${BASE_URL}/api/user/save`, {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.commit("setCurrentUser", data.payload);
     } else {
      context.commit("setError", data.message);
     }
    })
    .catch((err) => context.commit("setError", err));
  },

  async validatePassword(context, payload) {
   fetch(`${BASE_URL}/api/user/login`, {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     email: payload.email,
     password: payload.password,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.commit("setPasswordValidity", true);
     } else {
      context.commit("setPasswordValidity", false);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
 },
 mutations: {
  setPasswordValidity(state, payload) {
   state.validPassword = payload;
  },
 },
};
export default accountModule;
