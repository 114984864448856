<template>
 <header class="head">
  <p>{{ userLogin }}</p>
 </header>
</template>

<script>
export default {
 computed: {
  userLogin() {
   return this.$store.getters.getCurrentUser.email;
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
header {
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 font-size: 24px;
 padding: 0px 40px;
 display: flex;
 justify-content: end;
 align-items: center;
 width: 100%;
 height: 100%;
 background-color: var.$brand;
 border-radius: 16px;
 color: white;
}
@media screen and (max-width: 460px) {
 header {
  padding: 10px;
  height: auto;
  border-radius: 0px;
  justify-content: center;
 }
}
</style>
