<template>
 <table>
  <div class="loading" v-if="this.loading">
   <icon-loading />
  </div>
  <div class="table-head" v-if="this.windowWidth > 460 && !this.mode">
   <div v-for="item in tableHead" :key="item" @click="sort(item)">
    {{ item.title }}
    <img
     v-if="item.title === orderBy"
     src="../../assets/icons/icon-sort.svg"
     :class="{ down: !item.sort }"
    />
   </div>
  </div>
  <ul :class="{ card: this.mode }">
   <li v-for="item in tableList" :key="item.id">
    <table-list-item
     :mode="this.mode"
     :tableHead="this.tableHead"
     :gridColumns="gridColumns"
     :item="item"
     :displayItems="this.displayItems"
     @delete="deleteItem"
     @edit="edit"
    />
   </li>
  </ul>
 </table>
</template>

<script>
import iconLoading from "@/assets/icons/icon-loading.vue";
import TableListItem from "./TableListItem.vue";

export default {
 emits: ["delete", "sort", "edit"],
 components: { TableListItem, iconLoading },
 props: [
  "tableHead",
  "tableList",
  "orderBy",
  "loading",
  "displayItems",
  "mode",
 ],

 computed: {
  windowWidth() {
   return window.innerWidth;
  },
  gridColumns() {
   if (window.innerWidth > 460) {
    return `repeat(${this.tableHead.length},1fr) 60px`;
   }
   return `repeat(${this.tableHead.length},1fr)`;
  },
 },
 methods: {
  deleteItem(item) {
   this.$emit("delete", item);
  },
  sort(item) {
   this.$emit("sort", item);
  },
  edit(item) {
   this.$emit("edit", item);
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
ul {
 &::-webkit-scrollbar {
  width: 14px;
 }

 &::-webkit-scrollbar-track {
  background: transparent;
 }

 &::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: var.$grey;
 }
}
.loading {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 99999;
 background-color: rgba(0, 0, 0, 0.182);
 border-radius: 16px;
}
img.down {
 transform: rotate(180deg);
}

ul {
 border: solid 2px var.$grey;
 border-radius: 0px 0px 16px 16px;
 overflow-y: auto;
 list-style: none;
 display: grid;
 grid-template-columns: repeat(5, 1fr);
 grid-template-rows: repeat(3, 33.33%);
 border-radius: 20px;
 height: 100%;
 li {
  transition: 100ms;
  border-bottom-style: hidden;
  border-top-style: hidden;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 7px;
  width: 100%;
 }
}

table {
 position: relative;
 width: 100%;
 height: 100%;
}

.table-head {
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 font-size: 16px;
 background-color: var.$grey;
 padding: 15px;
 border-radius: 16px 16px 0px 0px;
 width: 100%;
 display: grid;
 grid-template-columns: v-bind(gridColumns);
 div {
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
 }
}
@media screen and (max-width: 1280px) {
 ul {
  height: 88%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 60%);
 }
}
@media screen and (max-width: 1000px) {
 ul {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 70%);
 }
}
@media screen and (max-width: 600px) {
 ul {
  height: 100%;
  grid-template-columns: repeat(1, 1fr);
  overflow-x: hidden;
  li {
   padding: 5px;
  }
 }
}
</style>
