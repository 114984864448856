<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M34.2141 43.6852C34.2141 44.8765 33.7311 45.9553 32.9503 46.7362C32.1694 47.517 31.0906 48 29.8993 48C28.708 48 27.6293 47.517 26.8484 46.7362C26.0675 45.9553 25.5846 44.8765 25.5846 43.6852V43.0454H34.2144V43.6852H34.2141ZM32.0453 45.8312C32.4507 45.4259 32.7418 44.9065 32.8666 44.3251H26.9321C27.0569 44.9065 27.3479 45.4259 27.7533 45.8312C28.3026 46.3802 29.0611 46.7199 29.8993 46.7199C30.7375 46.7199 31.4964 46.3802 32.0453 45.8312Z"
  />
  <path
   d="M28.801 16H30.9925C31.3777 16 31.7293 16.1587 31.9854 16.4141L31.9865 16.413C32.2411 16.6676 32.3995 17.0196 32.3995 17.407V18.7447C31.9786 18.6459 31.5508 18.5723 31.1194 18.5244V17.407C31.1194 17.3727 31.105 17.341 31.0816 17.3179V17.3154C31.0599 17.2937 31.0282 17.28 30.9925 17.28H28.801C28.7668 17.28 28.735 17.2945 28.7119 17.3179C28.6889 17.341 28.6741 17.3727 28.6741 17.407V18.5244C28.2427 18.5723 27.8149 18.6459 27.394 18.7447V17.407C27.394 17.0196 27.552 16.6676 27.807 16.413C28.0616 16.1583 28.4137 16 28.801 16Z"
  />
  <path
   d="M18.1561 39.3183C18.7729 38.6954 20.4406 36.8004 20.4406 34.5163V27.3555C20.4406 23.0411 22.9069 20.2783 26.0632 19.0679C27.2747 18.6034 28.5868 18.3711 29.8968 18.3711C31.2067 18.3711 32.5188 18.6034 33.7303 19.0679C36.1501 19.9956 38.1641 21.8357 38.9724 24.588H37.6195C36.8603 22.451 35.2217 21.0094 33.2752 20.2632C32.2116 19.8556 31.0552 19.6515 29.8968 19.6515C28.7383 19.6515 27.582 19.8553 26.5183 20.2632C23.8248 21.2958 21.7207 23.6596 21.7207 27.3558V34.5166C21.7207 36.4801 20.7248 38.1796 19.892 39.2577H18.7296C18.533 39.2577 18.3408 39.2786 18.1558 39.3186L18.1561 39.3183ZM39.9018 39.2573C39.069 38.1793 38.0732 36.4798 38.0732 34.5163V32.67H39.3532V34.5163C39.3532 36.7986 41.0185 38.6929 41.6367 39.3172C41.4534 39.2782 41.2637 39.2577 41.0693 39.2577H39.9022L39.9018 39.2573Z"
  />
  <path
   d="M37.3532 23.9479H45.3028C46.0451 23.9479 46.7192 24.2512 47.2076 24.7395C47.6959 25.2283 47.9992 25.9024 47.9992 26.6443V30.6131C47.9992 31.355 47.6959 32.0295 47.2076 32.5182C46.7192 33.0065 46.0447 33.3099 45.3028 33.3099H43.0079C42.9927 33.3099 42.8329 33.7448 42.6807 34.1589C42.3947 34.9376 42.1213 35.6813 41.3279 35.6813C40.5347 35.6813 40.2613 34.9376 39.975 34.1589C39.8228 33.7445 39.663 33.3099 39.6478 33.3099H37.3529C36.6106 33.3099 35.9362 33.0065 35.4478 32.5182C34.9595 32.0298 34.6561 31.3554 34.6561 30.6131V26.6443C34.6561 25.902 34.9595 25.2279 35.4478 24.7392C35.9362 24.2508 36.6106 23.9475 37.3529 23.9475L37.3532 23.9479ZM45.3028 25.2279H37.3532C36.9644 25.2279 36.6103 25.3873 36.3531 25.6445C36.0959 25.9016 35.9365 26.2555 35.9365 26.6443V30.6131C35.9365 31.0019 36.0963 31.3561 36.3531 31.6133C36.6103 31.8704 36.9641 32.0298 37.3532 32.0298H39.6482C40.5546 32.0298 40.8723 32.8947 41.1753 33.7189C41.3037 34.068 41.2301 34.4013 41.3282 34.4013C41.4263 34.4013 41.3527 34.068 41.4811 33.7189C41.7841 32.8947 42.1022 32.0298 43.0082 32.0298H45.3032C45.692 32.0298 46.0462 31.8701 46.303 31.6133C46.5601 31.3561 46.7196 31.0023 46.7196 30.6135V26.6446C46.7196 26.2558 46.5598 25.9016 46.303 25.6448C46.0458 25.3877 45.692 25.2283 45.3032 25.2283L45.3028 25.2279Z"
  />
  <path
   d="M18.7299 38.8647H41.0689C41.8202 38.8647 42.503 39.1717 42.9975 39.6662C43.4919 40.1606 43.7989 40.8434 43.7989 41.5947C43.7989 42.3463 43.4919 43.0287 42.9975 43.5232C42.503 44.0177 41.8202 44.3246 41.0689 44.3246H18.7299C17.9787 44.3246 17.2959 44.0177 16.8014 43.5232C16.3069 43.0287 16 42.346 16 41.5947C16 40.8434 16.3069 40.1606 16.8014 39.6662C17.2959 39.1717 17.9787 38.8647 18.7299 38.8647ZM41.0689 40.1448H18.7299C18.3318 40.1448 17.9693 40.3082 17.7063 40.5711C17.4434 40.834 17.28 41.1965 17.28 41.5947C17.28 41.9929 17.4434 42.3553 17.7063 42.6183C17.9693 42.8812 18.3318 43.0446 18.7299 43.0446H41.0689C41.4671 43.0446 41.8296 42.8812 42.0925 42.6183C42.3555 42.3553 42.5188 41.9929 42.5188 41.5947C42.5188 41.1965 42.3555 40.834 42.0925 40.5711C41.8296 40.3078 41.4671 40.1448 41.0689 40.1448Z"
  />
  <path
   d="M41.3283 29.7536C41.9494 29.7536 42.4529 29.2501 42.4529 28.629C42.4529 28.0079 41.9494 27.5044 41.3283 27.5044C40.7072 27.5044 40.2037 28.0079 40.2037 28.629C40.2037 29.2501 40.7072 29.7536 41.3283 29.7536Z"
  />
  <path
   d="M44.6269 29.7536C45.248 29.7536 45.7515 29.2501 45.7515 28.629C45.7515 28.0079 45.248 27.5044 44.6269 27.5044C44.0058 27.5044 43.5023 28.0079 43.5023 28.629C43.5023 29.2501 44.0058 29.7536 44.6269 29.7536Z"
  />
  <path
   d="M38.0296 29.7536C38.6507 29.7536 39.1542 29.2501 39.1542 28.629C39.1542 28.0079 38.6507 27.5044 38.0296 27.5044C37.4085 27.5044 36.905 28.0079 36.905 28.629C36.905 29.2501 37.4085 29.7536 38.0296 29.7536Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
