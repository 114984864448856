<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M18.3464 16H45.6536C46.2994 16 46.8865 16.2635 47.3115 16.6885C47.7365 17.1135 48 17.7006 48 18.3464V45.6536C48 46.2994 47.7365 46.8865 47.3115 47.3115C46.8865 47.7365 46.2994 48 45.6536 48H18.3464C17.7006 48 17.1135 47.7365 16.6885 47.3115C16.2635 46.8865 16 46.2994 16 45.6536V18.3464C16 17.7006 16.2635 17.1135 16.6885 16.6885C17.1135 16.2635 17.7006 16 18.3464 16ZM45.6536 17.2798H18.3464C18.0539 17.2798 17.7869 17.3998 17.5937 17.5937C17.3998 17.7876 17.2798 18.0539 17.2798 18.3464V45.6536C17.2798 45.9461 17.3998 46.2131 17.5937 46.4063C17.7876 46.6002 18.0539 46.7202 18.3464 46.7202H45.6536C45.9461 46.7202 46.2131 46.6002 46.4063 46.4063C46.6002 46.2125 46.7202 45.9461 46.7202 45.6536V18.3464C46.7202 18.0539 46.6002 17.7869 46.4063 17.5937C46.2125 17.3998 45.9461 17.2798 45.6536 17.2798Z"
  />
  <path
   d="M19.4861 18.3774H44.5146C44.8057 18.3774 45.072 18.4975 45.2659 18.6907L45.2687 18.6934C45.4618 18.888 45.5819 19.1536 45.5819 19.4447V23.1964C45.5819 23.4876 45.4618 23.7539 45.2687 23.9478L45.2659 23.9505C45.0713 24.1437 44.8057 24.2637 44.5146 24.2637H19.4861C19.195 24.2637 18.9294 24.1437 18.7348 23.9505L18.732 23.9478C18.5389 23.7532 18.4188 23.4876 18.4188 23.1964V19.4447C18.4188 19.1536 18.5389 18.888 18.732 18.6934L18.7348 18.6907C18.9294 18.4975 19.195 18.3774 19.4861 18.3774ZM44.3007 19.6572H19.6993V22.9819H44.3007V19.6572Z"
  />
  <path
   d="M27.3125 25.8835C28.6033 25.8835 29.7727 26.4072 30.6185 27.253C31.465 28.0988 31.988 29.2682 31.988 30.5591C31.988 31.8499 31.4643 33.0193 30.6185 33.8651C29.7727 34.7116 28.6033 35.2346 27.3125 35.2346C26.0217 35.2346 24.8523 34.7109 24.0064 33.8651C23.1599 33.0186 22.637 31.8499 22.637 30.5591C22.637 29.2682 23.1606 28.0988 24.0064 27.253C24.8529 26.4065 26.0217 25.8835 27.3125 25.8835ZM29.7133 28.1582C29.0986 27.5435 28.2501 27.1633 27.3125 27.1633C26.3749 27.1633 25.5256 27.5435 24.9109 28.1582C24.2962 28.7729 23.9161 29.6215 23.9161 30.5591C23.9161 31.4966 24.2962 32.3459 24.9109 32.9606C25.5256 33.5753 26.3742 33.9555 27.3125 33.9555C28.2501 33.9555 29.0993 33.5753 29.7133 32.9606C30.3281 32.3459 30.7082 31.4973 30.7082 30.5591C30.7082 29.6215 30.3281 28.7722 29.7133 28.1582Z"
  />
  <path
   d="M30.2789 35.6368C31.7795 36.1639 33.0965 37.0787 34.11 38.2612C35.1124 39.4306 35.8189 40.8629 36.1121 42.4414C36.288 43.3886 36.2101 43.9378 35.8244 44.3124C35.4477 44.6781 34.9358 44.7257 34.1762 44.7257H20.2601V44.7229C19.5943 44.7215 19.1245 44.6622 18.7768 44.3048C18.4312 43.9495 18.3435 43.4314 18.4919 42.5635C18.7665 40.9595 19.4681 39.501 20.4754 38.3088C21.4937 37.1043 22.8266 36.1715 24.3485 35.6375L24.6279 35.5396L24.8832 35.69C25.2426 35.9018 25.6352 36.0687 26.0519 36.1825C26.4534 36.2916 26.8763 36.3502 27.3137 36.3502C27.7511 36.3502 28.174 36.2916 28.5756 36.1825C28.9923 36.0694 29.3841 35.9024 29.7443 35.69L29.9995 35.5396L30.2789 35.6375V35.6368ZM33.14 39.0912C32.3307 38.1474 31.2973 37.4023 30.123 36.9401C29.7394 37.1408 29.3338 37.3016 28.9109 37.4168C28.4017 37.5555 27.8663 37.6293 27.3144 37.6293C26.7625 37.6293 26.2271 37.5555 25.718 37.4168C25.2951 37.3016 24.8887 37.1408 24.5058 36.9401C23.3136 37.4092 22.267 38.1695 21.4516 39.1333C20.5885 40.1537 19.9883 41.4038 19.753 42.7781C19.6813 43.1962 19.664 43.3845 19.693 43.4142C19.7199 43.4418 19.9296 43.4466 20.2615 43.4473V43.4445H34.1776C34.6178 43.4445 34.8917 43.439 34.9358 43.3962C34.971 43.3624 34.9469 43.1472 34.8586 42.6704C34.6074 41.321 34.0017 40.0936 33.1414 39.0898L33.14 39.0912Z"
  />
  <path d="M38.1447 26.9241H44.9409V28.2045H38.1447V26.9241Z" />
  <path d="M38.1447 43.4451H44.9409V44.7248H38.1447V43.4451Z" />
  <path d="M38.1447 39.3149H44.9409V40.5947H38.1447V39.3149Z" />
  <path d="M38.1447 35.1841H44.9409V36.4646H38.1447V35.1841Z" />
  <path d="M38.1447 31.0544H44.9409V32.3342H38.1447V31.0544Z" />
  <path
   d="M29.2669 22.4444C29.888 22.4444 30.3914 21.9409 30.3914 21.3199C30.3914 20.6988 29.888 20.1953 29.2669 20.1953C28.6458 20.1953 28.1423 20.6988 28.1423 21.3199C28.1423 21.9409 28.6458 22.4444 29.2669 22.4444Z"
  />
  <path
   d="M23.8016 22.4444C24.4226 22.4444 24.9261 21.9409 24.9261 21.3199C24.9261 20.6988 24.4226 20.1953 23.8016 20.1953C23.1805 20.1953 22.677 20.6988 22.677 21.3199C22.677 21.9409 23.1805 22.4444 23.8016 22.4444Z"
  />
  <path
   d="M34.7331 22.4444C35.3541 22.4444 35.8576 21.9409 35.8576 21.3199C35.8576 20.6988 35.3541 20.1953 34.7331 20.1953C34.112 20.1953 33.6085 20.6988 33.6085 21.3199C33.6085 21.9409 34.112 22.4444 34.7331 22.4444Z"
  />
  <path
   d="M40.1991 22.4444C40.8202 22.4444 41.3237 21.9409 41.3237 21.3199C41.3237 20.6988 40.8202 20.1953 40.1991 20.1953C39.5781 20.1953 39.0746 20.6988 39.0746 21.3199C39.0746 21.9409 39.5781 22.4444 40.1991 22.4444Z"
  />
  <path
   d="M38.1446 28.2045C38.4982 28.2045 38.7849 27.9179 38.7849 27.5643C38.7849 27.2107 38.4982 26.9241 38.1446 26.9241C37.791 26.9241 37.5044 27.2107 37.5044 27.5643C37.5044 27.9179 37.791 28.2045 38.1446 28.2045Z"
  />
  <path
   d="M44.9409 28.2045C45.2945 28.2045 45.5811 27.9179 45.5811 27.5643C45.5811 27.2107 45.2945 26.9241 44.9409 26.9241C44.5873 26.9241 44.3007 27.2107 44.3007 27.5643C44.3007 27.9179 44.5873 28.2045 44.9409 28.2045Z"
  />
  <path
   d="M38.1446 32.3349C38.4982 32.3349 38.7849 32.0483 38.7849 31.6947C38.7849 31.3411 38.4982 31.0544 38.1446 31.0544C37.791 31.0544 37.5044 31.3411 37.5044 31.6947C37.5044 32.0483 37.791 32.3349 38.1446 32.3349Z"
  />
  <path
   d="M44.9409 32.3349C45.2945 32.3349 45.5811 32.0483 45.5811 31.6947C45.5811 31.3411 45.2945 31.0544 44.9409 31.0544C44.5873 31.0544 44.3007 31.3411 44.3007 31.6947C44.3007 32.0483 44.5873 32.3349 44.9409 32.3349Z"
  />
  <path
   d="M38.1446 36.455C38.4982 36.455 38.7849 36.1684 38.7849 35.8148C38.7849 35.4612 38.4982 35.1746 38.1446 35.1746C37.791 35.1746 37.5044 35.4612 37.5044 35.8148C37.5044 36.1684 37.791 36.455 38.1446 36.455Z"
  />
  <path
   d="M44.9409 36.455C45.2945 36.455 45.5811 36.1684 45.5811 35.8148C45.5811 35.4612 45.2945 35.1746 44.9409 35.1746C44.5873 35.1746 44.3007 35.4612 44.3007 35.8148C44.3007 36.1684 44.5873 36.455 44.9409 36.455Z"
  />
  <path
   d="M38.1446 40.5954C38.4982 40.5954 38.7849 40.3088 38.7849 39.9552C38.7849 39.6016 38.4982 39.3149 38.1446 39.3149C37.791 39.3149 37.5044 39.6016 37.5044 39.9552C37.5044 40.3088 37.791 40.5954 38.1446 40.5954Z"
  />
  <path
   d="M44.9409 40.5954C45.2945 40.5954 45.5811 40.3088 45.5811 39.9552C45.5811 39.6016 45.2945 39.3149 44.9409 39.3149C44.5873 39.3149 44.3007 39.6016 44.3007 39.9552C44.3007 40.3088 44.5873 40.5954 44.9409 40.5954Z"
  />
  <path
   d="M38.1446 44.725C38.4982 44.725 38.7849 44.4384 38.7849 44.0848C38.7849 43.7312 38.4982 43.4446 38.1446 43.4446C37.791 43.4446 37.5044 43.7312 37.5044 44.0848C37.5044 44.4384 37.791 44.725 38.1446 44.725Z"
  />
  <path
   d="M44.9409 44.725C45.2945 44.725 45.5811 44.4384 45.5811 44.0848C45.5811 43.7312 45.2945 43.4446 44.9409 43.4446C44.5873 43.4446 44.3007 43.7312 44.3007 44.0848C44.3007 44.4384 44.5873 44.725 44.9409 44.725Z"
  />
 </svg>
</template>

<style lang="scss">
svg {
 min-width: 64px;
 min-height: 64px;
}
</style>
