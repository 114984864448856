<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M19.098 16.0006H35.8231C36.6757 16.0006 37.45 16.3489 38.011 16.9099C38.5721 17.471 38.9204 18.2453 38.9204 19.0979V41.4206C38.9204 42.2731 38.5721 43.0475 38.011 43.6085C37.45 44.1695 36.6757 44.5179 35.8231 44.5179H31.176V43.2381H35.8231C36.3221 43.2381 36.7765 43.0335 37.1063 42.7038C37.4361 42.374 37.6407 41.9202 37.6407 41.4206V19.0979C37.6407 18.5989 37.4361 18.1445 37.1063 17.8147C36.7765 17.4849 36.3227 17.2803 35.8231 17.2803H19.098C19.0812 17.2803 19.065 17.2803 19.0482 17.2809L18.2912 17.0792C17.3836 16.8375 16.6406 17.4351 16.6406 18.4076V42.1103C16.6406 43.0822 17.383 44.0756 18.2912 44.3167L19.0435 44.5167C18.2124 44.5022 17.459 44.1568 16.9101 43.6079C16.3491 43.0469 16.0007 42.2725 16.0007 41.42V19.0973C16.0007 18.2447 16.3491 17.4704 16.9101 16.9094C17.4711 16.3483 18.2455 16 19.098 16V16.0006Z"
  />
  <path
   d="M18.4534 16.4624L29.6875 19.4519C30.2844 19.6107 30.8194 20.0066 31.2008 20.516H31.2031C31.581 21.0214 31.8151 21.6485 31.8151 22.2756V45.9783C31.8151 46.1869 31.7879 46.3881 31.7375 46.5782C31.6233 47.0065 31.3909 47.3595 31.0756 47.6075C30.7556 47.8596 30.3586 47.9999 29.9199 47.9999C29.7419 47.9999 29.5547 47.975 29.3617 47.924L18.1277 44.9345C17.5289 44.7751 16.9928 44.3792 16.612 43.8704L16.6138 43.8692C16.2347 43.3621 16 42.7355 16 42.1107V18.4081C16 18.1994 16.0272 17.9983 16.0777 17.8082C16.1918 17.3799 16.4243 17.0269 16.739 16.7789C17.0589 16.5267 17.4559 16.3865 17.8947 16.3865C18.0726 16.3865 18.2598 16.4114 18.4528 16.4624H18.4534ZM29.3629 20.687L18.1288 17.6975C18.0494 17.6766 17.9712 17.6662 17.8952 17.6662C17.7509 17.6662 17.6257 17.7085 17.5295 17.7839C17.4293 17.8633 17.3527 17.9826 17.3128 18.1333C17.2913 18.2127 17.2803 18.3049 17.2803 18.4081V42.1107C17.2803 42.4573 17.4165 42.8126 17.635 43.1053H17.6373C17.8547 43.3957 18.1439 43.6177 18.4528 43.7L29.6869 46.6895C29.7663 46.7103 29.8445 46.7207 29.9205 46.7207C30.0648 46.7207 30.19 46.6784 30.2862 46.6031C30.3864 46.5237 30.4629 46.4043 30.5029 46.2536C30.5244 46.1742 30.5354 46.082 30.5354 45.9789V22.2762C30.5354 21.9314 30.3986 21.5761 30.1778 21.2816L30.1795 21.2805C29.9628 20.9907 29.673 20.7699 29.3623 20.6876L29.3629 20.687Z"
  />
  <path
   d="M42.5762 26.189L47.5068 31.1196C47.8319 31.4447 47.9953 31.6383 48 31.9913C48.0046 32.3518 47.8423 32.5453 47.5172 32.8711L42.5762 37.812C42.4597 37.932 42.2969 38.0068 42.1166 38.0068C41.7631 38.0068 41.4768 37.7204 41.4768 37.3669C41.4768 37.1872 41.551 37.0249 41.6698 36.909L45.9384 32.6404H31.1787V31.3607H45.9384L41.6709 27.0932C41.5515 26.9767 41.4773 26.8144 41.4773 26.6347C41.4773 26.2812 41.7637 25.9949 42.1172 25.9949C42.2975 25.9949 42.4603 26.0696 42.5768 26.1896L42.5762 26.189Z"
  />
 </svg>
</template>

<style lang="scss">
svg {
 min-width: 64px;
 min-height: 64px;
}
</style>
