const SetStortMixin = {
 methods: {
  setSort(val) {
   this.orderbyRu = val.title;
   if (val.field === this.searchOptions.orderby) {
    const item = this.tableHead.find((el) => el.title === val.title);
    item.sort = !item.sort;
    if (item.sort) {
     this.searchOptions.orderdir = "asc";
    } else {
     this.searchOptions.orderdir = "desc";
    }
   }
   this.searchOptions.orderby = val.field;
  },
 },
};
export default SetStortMixin;
