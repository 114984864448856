<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M18.3467 16H35.3112C35.9572 16 36.5441 16.2639 36.969 16.6888C37.3941 17.1138 37.6578 17.7006 37.6578 18.3466V28.8973H36.3778V18.3466C36.3778 18.0539 36.2577 17.7873 36.064 17.5937C35.8705 17.4001 35.6039 17.2798 35.3111 17.2798H18.3466C18.0539 17.2798 17.7873 17.4 17.5938 17.5937C17.4003 17.7872 17.28 18.0537 17.28 18.3466V45.6533C17.28 45.946 17.4001 46.2126 17.5938 46.4062C17.7873 46.5997 18.0539 46.72 18.3466 46.72H29.351C29.6646 47.1096 30.1447 47.36 30.6807 47.36H36.9185C36.4981 47.7563 35.9319 48 35.3111 48H18.3466C17.7006 48 17.1138 47.7361 16.6888 47.3112C16.2637 46.8862 16 46.2994 16 45.6534V18.3467C16 17.7008 16.2639 17.1138 16.6888 16.6889C17.1138 16.2639 17.7006 16.0001 18.3466 16.0001L18.3467 16Z"
  />
  <path
   d="M30.6808 28.2573H45.6534C46.2994 28.2573 46.8862 28.5212 47.3113 28.9461C47.7364 29.3712 48.0001 29.9579 48.0001 30.6039V45.6533C48.0001 46.2992 47.7363 46.8861 47.3113 47.3111C46.8863 47.7361 46.2994 47.9998 45.6534 47.9998H30.6808C30.0349 47.9998 29.4479 47.736 29.023 47.3111C28.5981 46.8861 28.3342 46.2992 28.3342 45.6533V30.6039C28.3342 29.9579 28.5981 29.371 29.023 28.9461C29.4481 28.5211 30.0349 28.2573 30.6808 28.2573ZM45.6534 29.5373H30.6808C30.3881 29.5373 30.1215 29.6575 29.9279 29.8511C29.7344 30.0446 29.6141 30.3112 29.6141 30.604V45.6534C29.6141 45.9461 29.7342 46.2127 29.9279 46.4062C30.1214 46.5997 30.388 46.72 30.6808 46.72H45.6534C45.9461 46.72 46.2127 46.5998 46.4063 46.4062C46.5999 46.2127 46.7202 45.9461 46.7202 45.6534V30.604C46.7202 30.3114 46.6 30.0448 46.4063 29.8511C46.2127 29.6576 45.9461 29.5373 45.6534 29.5373Z"
  />
  <path
   d="M36.5255 42.5063H39.809C40.1029 42.5063 40.3698 42.6262 40.5627 42.8192C40.7557 43.0121 40.8755 43.279 40.8755 43.5729V46.9332C40.8755 47.2254 40.7557 47.4915 40.5627 47.6845L40.5603 47.687C40.3672 47.8799 40.1012 47.9998 39.809 47.9998H36.5255C36.2315 47.9998 35.9646 47.8799 35.7717 47.687C35.5786 47.4939 35.4589 47.2271 35.4589 46.9332V43.5729C35.4589 43.2818 35.5786 43.016 35.7717 42.8216L35.7741 42.8192C35.9683 42.6261 36.2343 42.5063 36.5255 42.5063ZM39.5957 43.7863H36.7388V46.7198H39.5957V43.7863Z"
  />
  <path
   d="M20.9908 19.8708H24.4142C24.7081 19.8708 24.975 19.9907 25.1679 20.1837C25.3609 20.3766 25.4808 20.6435 25.4808 20.9374V24.44C25.4808 24.7322 25.3609 24.9983 25.1679 25.1913L25.1655 25.1938C24.9724 25.3867 24.7064 25.5066 24.4142 25.5066H20.9908C20.6968 25.5066 20.43 25.3867 20.237 25.1938C20.0439 25.0007 19.9242 24.7339 19.9242 24.44V20.9374C19.9242 20.6463 20.0439 20.3805 20.237 20.1861L20.2394 20.1837C20.4337 19.9906 20.6997 19.8708 20.9908 19.8708ZM24.2008 21.1508H21.204V24.2268H24.2008V21.1508Z"
  />
  <path
   d="M29.2441 19.8708H32.6675C32.9614 19.8708 33.2283 19.9907 33.4212 20.1837C33.6142 20.3766 33.7341 20.6435 33.7341 20.9374V24.44C33.7341 24.7322 33.6142 24.9983 33.4212 25.1913L33.4188 25.1938C33.2257 25.3867 32.9597 25.5066 32.6675 25.5066H29.2441C28.9501 25.5066 28.6833 25.3867 28.4903 25.1938C28.2972 25.0007 28.1775 24.7339 28.1775 24.44V20.9374C28.1775 20.6463 28.2972 20.3805 28.4903 20.1861L28.4927 20.1837C28.687 19.9906 28.953 19.8708 29.2441 19.8708ZM32.4541 21.1508H29.4573V24.2268H32.4541V21.1508Z"
  />
  <path
   d="M20.9908 28.1648H24.4142C24.7081 28.1648 24.975 28.2847 25.1679 28.4776C25.3609 28.6706 25.4808 28.9374 25.4808 29.2314V32.7339C25.4808 33.0262 25.3609 33.2922 25.1679 33.4853L25.1655 33.4877C24.9724 33.6806 24.7064 33.8005 24.4142 33.8005H20.9908C20.6968 33.8005 20.43 33.6806 20.237 33.4877C20.0439 33.2946 19.9242 33.0279 19.9242 32.7339V29.2314C19.9242 28.9403 20.0439 28.6744 20.237 28.48L20.2394 28.4776C20.4337 28.2845 20.6997 28.1648 20.9908 28.1648ZM24.2008 29.4448H21.204V32.5207H24.2008V29.4448Z"
  />
  <path
   d="M20.9908 36.4585H24.4142C24.7081 36.4585 24.975 36.5784 25.1679 36.7713C25.361 36.9644 25.4808 37.2311 25.4808 37.5251V41.0276C25.4808 41.3216 25.3609 41.5885 25.1679 41.7814C24.9748 41.9745 24.7081 42.0942 24.4142 42.0942H20.9908C20.6968 42.0942 20.43 41.9743 20.237 41.7814C20.0441 41.5885 19.9242 41.3215 19.9242 41.0276V37.5251C19.9242 37.2311 20.0441 36.9643 20.237 36.7713C20.43 36.5784 20.6968 36.4585 20.9908 36.4585ZM24.2008 37.7385H21.204V40.8144H24.2008V37.7385Z"
  />
  <path
   d="M31.7711 31.3701H34.3523C34.6462 31.3701 34.9131 31.49 35.1061 31.6829C35.299 31.8759 35.4189 32.1428 35.4189 32.4367V35.0815C35.4189 35.3738 35.299 35.6398 35.1061 35.8328L35.1036 35.8353C34.9106 36.0282 34.6445 36.1481 34.3523 36.1481H31.7711C31.4771 36.1481 31.2102 36.0282 31.0173 35.8353C30.8242 35.6422 30.7045 35.3755 30.7045 35.0815V32.4367C30.7045 32.1456 30.8242 31.8797 31.0173 31.6854L31.0197 31.6829C31.2139 31.4899 31.4799 31.3701 31.7711 31.3701ZM34.1389 32.6501H31.9844V34.8683H34.1389V32.6501Z"
  />
  <path
   d="M36.8767 31.3701H39.4579C39.7518 31.3701 40.0187 31.49 40.2116 31.6829C40.4046 31.8759 40.5245 32.1428 40.5245 32.4367V35.0815C40.5245 35.3738 40.4046 35.6398 40.2116 35.8328L40.2092 35.8353C40.0161 36.0282 39.7501 36.1481 39.4579 36.1481H36.8767C36.5827 36.1481 36.3158 36.0282 36.1229 35.8353C35.9298 35.6422 35.8101 35.3755 35.8101 35.0815V32.4367C35.8101 32.1456 35.9298 31.8797 36.1229 31.6854L36.1253 31.6829C36.3195 31.4899 36.5855 31.3701 36.8767 31.3701ZM39.2445 32.6501H37.09V34.8683H39.2445V32.6501Z"
  />
  <path
   d="M41.9822 31.3701H44.5635C44.8574 31.3701 45.1243 31.49 45.3172 31.6829C45.5102 31.8759 45.6301 32.1428 45.6301 32.4367V35.0815C45.6301 35.3738 45.5102 35.6398 45.3172 35.8328L45.3148 35.8353C45.1217 36.0282 44.8557 36.1481 44.5635 36.1481H41.9822C41.6883 36.1481 41.4214 36.0282 41.2285 35.8353C41.0354 35.6422 40.9156 35.3755 40.9156 35.0815V32.4367C40.9156 32.1456 41.0354 31.8797 41.2285 31.6854L41.2309 31.6829C41.4251 31.4899 41.6911 31.3701 41.9822 31.3701ZM44.3501 32.6501H42.1956V34.8683H44.3501V32.6501Z"
  />
  <path
   d="M31.7711 36.5276H34.3523C34.6462 36.5276 34.9131 36.6475 35.1061 36.8404C35.299 37.0334 35.4189 37.3002 35.4189 37.5942V40.239C35.4189 40.5312 35.299 40.7972 35.1061 40.9903L35.1036 40.9927C34.9106 41.1857 34.6445 41.3056 34.3523 41.3056H31.7711C31.4771 41.3056 31.2102 41.1857 31.0173 40.9927C30.8242 40.7996 30.7045 40.5329 30.7045 40.239V37.5942C30.7045 37.3031 30.8242 37.0372 31.0173 36.8428L31.0197 36.8404C31.2139 36.6473 31.4799 36.5276 31.7711 36.5276ZM34.1389 37.8076H31.9844V40.0257H34.1389V37.8076Z"
  />
  <path
   d="M36.8767 36.5276H39.4579C39.7518 36.5276 40.0187 36.6475 40.2116 36.8404C40.4046 37.0334 40.5245 37.3002 40.5245 37.5942V40.239C40.5245 40.5312 40.4046 40.7972 40.2116 40.9903L40.2092 40.9927C40.0161 41.1857 39.7501 41.3056 39.4579 41.3056H36.8767C36.5827 41.3056 36.3158 41.1857 36.1229 40.9927C35.9298 40.7996 35.8101 40.5329 35.8101 40.239V37.5942C35.8101 37.3031 35.9298 37.0372 36.1229 36.8428L36.1253 36.8404C36.3195 36.6473 36.5855 36.5276 36.8767 36.5276ZM39.2445 37.8076H37.09V40.0257H39.2445V37.8076Z"
  />
  <path
   d="M41.9822 36.5276H44.5635C44.8574 36.5276 45.1243 36.6475 45.3172 36.8404C45.5102 37.0334 45.6301 37.3002 45.6301 37.5942V40.239C45.6301 40.5312 45.5102 40.7972 45.3172 40.9903L45.3148 40.9927C45.1217 41.1857 44.8557 41.3056 44.5635 41.3056H41.9822C41.6883 41.3056 41.4214 41.1857 41.2285 40.9927C41.0354 40.7996 40.9156 40.5329 40.9156 40.239V37.5942C40.9156 37.3031 41.0354 37.0372 41.2285 36.8428L41.2309 36.8404C41.4251 36.6473 41.6911 36.5276 41.9822 36.5276ZM44.3501 37.8076H42.1956V40.0257H44.3501V37.8076Z"
  />
  <path d="M42.5259 28.8973V21.1494H43.806V28.8973H42.5259Z" />
  <path d="M45.9609 21.7894H40.371V20.5093H45.9609V21.7894Z" />
  <path
   d="M45.9609 21.7892C46.3144 21.7892 46.6009 21.5027 46.6009 21.1493C46.6009 20.7958 46.3144 20.5093 45.9609 20.5093C45.6075 20.5093 45.3209 20.7958 45.3209 21.1493C45.3209 21.5027 45.6075 21.7892 45.9609 21.7892Z"
  />
  <path
   d="M40.3709 21.7892C40.7244 21.7892 41.0109 21.5027 41.0109 21.1493C41.0109 20.7958 40.7244 20.5093 40.3709 20.5093C40.0175 20.5093 39.731 20.7958 39.731 21.1493C39.731 21.5027 40.0175 21.7892 40.3709 21.7892Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
