<template>
 <div class="users-list-container">
  <div>
   <div class="search" v-if="this.windowWidth > 1280">
    <base-button
     mode="full"
     title="Создать"
     w="100"
     @click="popupIsOpen = true"
    />
    <base-input
     :w="100"
     mode="select"
     defaultOption="Без сортировки"
     :options="[...roleOptionsAll, 'Без сортировки']"
     @selected="
      this.searchOptions.userrole_id = this.convertRoleString($event, 1)
     "
    />
    <base-input
     :w="100"
     placeholder="ФИО"
     :value="this.searchOptions.name"
     @data="setSearchOptions('name', $event)"
    />
    <base-input
     :w="100"
     placeholder="Логин"
     :value="this.searchOptions.email"
     @data="setSearchOptions('email', $event)"
    />
    <base-input
     :w="100"
     placeholder="Телефон"
     :value="this.searchOptions.phone"
     @data="setSearchOptions('phone', $event)"
    />
    <base-input
     :w="100"
     placeholder="Организация"
     :value="this.searchOptions.company"
     @data="setSearchOptions('company', $event)"
    />
    <base-input
     :w="100"
     placeholder="Должность"
     :value="this.searchOptions.position"
     @data="setSearchOptions('position', $event)"
    />
   </div>
   <div class="search" v-else>
    <base-button
     mode="full"
     title="Создать"
     w="100"
     @click="popupIsOpen = true"
    />
    <base-button
     mode="flat"
     title="Фильтры"
     w="100"
     @click="this.searchPopupIsOpen = true"
    />
    <base-popup v-if="this.searchPopupIsOpen">
     <div class="form">
      <base-input
       :w="100"
       :types="this.roles"
       :value="this.roelStringRu"
       mode="select"
       defaultOption="Без сортировки"
       :options="[...roleOptionsAll, 'Без сортировки']"
       @selected="
        this.searchOptions.userrole_id = this.convertRoleString($event, 1);
        this.roelStringRu = $event;
       "
      />

      <base-input
       :w="100"
       placeholder="ФИО"
       :value="this.searchOptions.name"
       @data="setSearchOptions('name', $event)"
      />
      <base-input
       :w="100"
       placeholder="Логин"
       :value="this.searchOptions.email"
       @data="setSearchOptions('email', $event)"
      />
      <base-input
       :w="100"
       placeholder="Телефон"
       :value="this.searchOptions.phone"
       @data="setSearchOptions('phone', $event)"
      />
      <base-input
       :w="100"
       placeholder="Организация"
       :value="this.searchOptions.company"
       @data="setSearchOptions('company', $event)"
      />
      <base-input
       :w="100"
       placeholder="Должность"
       :value="this.searchOptions.position"
       @data="setSearchOptions('position', $event)"
      />
      <base-button
       title="Закрыть"
       mode="flat"
       @click="this.searchPopupIsOpen = false"
      />
     </div>
    </base-popup>
   </div>
  </div>

  <div class="table-container">
   <section v-if="list.length > 0">
    <table-list
     mode="1"
     :displayItems="['email', 'name', 'userrole_id']"
     :loading="this.loading"
     :orderBy="this.orderbyRu"
     :tableHead="this.windowWidth > 600 ? tableHead : tableHeadMobile"
     :tableList="list"
     @edit="editUser"
     @delete="deleteUser"
     @sort="setSort"
    />
   </section>
   <section class="not-found" v-else>Ничего не найдено</section>
   <div class="pages" v-if="list.length > 0">
    <div class="icon">
     <img src="../../assets/icons/arrow.svg" @click="switchPage(2)" />
    </div>
    <p>{{ pagesData.page }} из {{ pagesData.totalPages }}</p>

    <input v-debounce:300ms="setPerPage" :value="this.localPagesData.perPage" />
    <div class="icon">
     <img src="../../assets/icons/arrow.svg" @click="switchPage(1)" />
    </div>
   </div>
  </div>
 </div>

 <base-popup v-if="popupIsOpen">
  <div class="form">
   <base-input
    label="Логин"
    @data="this.createUserLocal.email = $event"
    :value="this.createUserLocal.email"
    w="60"
   />

   <base-input
    label="ФИО"
    @data="this.createUserLocal.name = $event"
    :value="this.createUserLocal.name"
    w="60"
   />

   <base-input
    label="Телефон"
    @data="this.createUserLocal.phone = $event"
    :value="this.createUserLocal.phone"
    w="60"
   />

   <base-input
    w="60"
    ref="select"
    mode="select-search"
    label="Организация"
    :userId="editUserId"
    :defaultOption="this.createUserLocal.company"
    @selected="this.createUserLocal.company = $event"
   />

   <base-input
    w="60"
    label="Должность"
    @data="this.createUserLocal.position = $event"
    :value="this.createUserLocal.position"
   />

   <base-input
    w="60"
    label="Пароль"
    @data="this.createUserLocal.password = $event"
    :value="this.createUserLocal.password"
   />

   <base-input
    w="60"
    label="Подтверждение пароля"
    @data="this.createUserLocal.password2 = $event"
    :value="this.createUserLocal.password2"
   />

   <base-input
    w="60"
    @selected="
     this.createUserLocal.userrole_id = this.convertRoleString($event, 1)
    "
    label="Права Доступа"
    mode="select"
    :options="roleOptions"
    :defaultOption="this.createUserLocal.userrole_id"
   />

   <div class="btns">
    <base-button mode="flat" title="Отмена" @click="closePopup(true)" />
    <base-button
     v-if="isEdit"
     :mode="validCreateUserLocal"
     title="Изменить"
     @click="edit"
    />
    <base-button
     v-else
     :mode="validCreateUserLocal"
     title="Создать"
     @click="createUser"
    />
   </div>
  </div>
 </base-popup>
</template>

<script>
import { debounce } from "vue-debounce";
import TableList from "../UI/TableList.vue";
import BasePopup from "../UI/BasePopup.vue";
import BaseButton from "../UI/BaseButton.vue";
import PagesMixin from "../mixins/PagesMixin";
import SetStortMixin from "../mixins/SetSortMixin";
export default {
 mixins: [PagesMixin, SetStortMixin],
 components: { TableList, BasePopup, BaseButton },
 props: ["windowWidth"],
 data() {
  return {
   orderbyRu: "",
   roelStringRu: "",
   searchOptions: {
    options: {},

    email: null,
    name: null,
    phone: null,
    company: null,
    position: null,
    userrole_id: -1,
    orderby: "",
    orderdir: "asc",
   },
   localPagesData: {
    currentPage: 1,
    perPage: 5,
   },
   popupIsOpen: false,
   searchPopupIsOpen: false,

   isEdit: false,
   editUserId: null,
   createUserLocal: {
    name: null,
    email: null,
    phone: null,
    password: null,
    password2: null,
    company: null,
    position: null,
    userrole_id: null,
   },
   tableHead: [
    {
     title: "Логин",
     sort: true,
     field: "email",
    },
    {
     title: "ФИО",
     sort: true,
     field: "name",
    },
    {
     title: "Права доступа",
     sort: true,
     field: "userrole_id",
    },
    {
     title: "Организация",
     sort: true,
     field: "company",
    },
    {
     title: "Должность",
     sort: true,
     field: "position",
    },

    {
     title: "Телефон",
     sort: true,
     field: "phone",
    },
   ],
   tableHeadMobile: [
    {
     title: "Логин",
     sort: true,
     field: "email",
    },
    {
     title: "ФИО",
     sort: true,
     field: "name",
    },
    {
     title: "Права",
     sort: true,
     field: "userrole_id",
    },
   ],
  };
 },
 computed: {
  loading() {
   return this.$store.getters.getUsersLoading;
  },
  setSearchOptions() {
   const fn = debounce((target, val) => {
    this.searchOptions[target] = val;
   }, "400ms");
   return fn;
  },
  validCreateUserLocal() {
   for (let el in this.createUserLocal) {
    if (!this.createUserLocal[el] && el !== "password" && el !== "password2") {
     return "disabled";
    }
   }
   return "full";
  },
  pagesData() {
   return this.$store.getters.getPagesData;
  },
  userRights() {
   return this.$store.getters.getCurrnetUserRights;
  },
  roleOptions() {
   let roleArr = [];
   if (this.roles) {
    for (let el of this.roles) {
     if (el.userrole_id !== "1" && el.userrole_id !== "2") {
      roleArr.push(el.name);
     }
    }
    if (this.userRights.includes("editadmin")) {
     roleArr.push("Администратор");
    }
    if (this.userRights.includes("editmanager")) {
     roleArr.push("Менеджер пользователей");
    }
   }

   return roleArr;
  },
  roleOptionsAll() {
   let roleArr = [];
   if (this.roles) {
    for (let el of this.roles) {
     roleArr.push(el.name);
    }
   }
   return roleArr;
  },

  roles() {
   return this.$store.getters.getRoles;
  },

  list() {
   if (this.$store.getters.getUsersList) {
    let arr = structuredClone(this.$store.getters.getUsersList);
    arr = arr.filter(
     (el) => this.$store.getters.getCurrentUser.user_id != el.user_id
    );

    if (this.$store.getters.getCurrentUser.userrole_id === "2") {
     arr = arr.filter((el) => el.userrole_id !== "1" && el.userrole_id !== "2");
    }

    for (let el of arr) {
     el.id = el.user_id;
     el.list = function (fields) {
      let arr = [];
      for (let field of fields) {
       arr.push(this[field]);
      }
      return arr;
     };
    }
    for (let el of arr) {
     el.userrole_id = this.convertRoleString(el.userrole_id, 2);
    }
    return arr;
   }
   return [];
  },
 },

 watch: {
  localPagesData: {
   handler(newValue) {
    if (!newValue.page) {
     newValue.page = 5;
    }
    this.$store.dispatch("setPage", { ...newValue, fetch: true });
   },
   deep: true,
  },
  searchOptions: {
   handler(newValue) {
    const payload = Object.values(newValue);
    for (let el of payload) {
     if (el !== null || !newValue.options.searchReqest) {
      this.$store.dispatch("setPage", { currentPage: 1, numonpage: 5 });
      this.$store.dispatch("setSearchOptions", newValue);
      return;
     }
    }
   },
   deep: true,
  },
 },
 methods: {
  editUser(item) {
   this.editUserId = item.user_id;
   this.createUserLocal.name = item.name;
   this.createUserLocal.email = item.email;
   this.createUserLocal.phone = item.phone;
   this.createUserLocal.company = item.company;
   this.createUserLocal.position = item.position;
   this.createUserLocal.userrole_id = item.userrole_id;
   this.popupIsOpen = true;
   this.isEdit = true;
  },
  edit() {
   if (typeof this.createUserLocal.userrole_id === "string") {
    this.createUserLocal.userrole_id = this.convertRoleString(
     this.createUserLocal.userrole_id,
     1
    );
   }

   this.$store.dispatch("save", {
    ...this.createUserLocal,
    user_id: this.editUserId,
   });
   if (this.$refs.select.orgId) {
    this.$store.dispatch("linkUser", {
     organization_id: this.$refs.select.orgId,
     user_id: this.editUserId,
    });
   }

   this.closePopup();
  },
  deleteUser(item) {
   this.$store.dispatch("delete", item.user_id);
  },
  closePopup(fetch) {
   this.popupIsOpen = false;
   this.isEdit = false;
   if (fetch) {
    this.$store.dispatch("getOrgList", {
     name: null,
     currentPage: 1,
     numonpage: 5,
    });
   }
   this.setNull(this.createUserLocal);
  },
  createUser() {
   if (!this.createUserLocal.userrole_id) {
    this.createUserLocal.userrole_id = 5;
   }
   this.$store.dispatch("setSelectedOrg", this.$refs.select.orgId);
   this.$store.dispatch("create", this.createUserLocal);

   this.closePopup();
  },

  convertRoleString(val, direction) {
   if (this.roles) {
    if (direction === 1) {
     if (typeof val === "string") {
      let userrole_id = this.roles.find((el) => el.name === val);
      if (userrole_id) {
       return Number(userrole_id.userrole_id);
      }
      return -1;
     }
    } else {
     let userroleString = this.roles.find((el) => el.userrole_id === val);
     if (userroleString) {
      return userroleString.name;
     }
     return null;
    }
   }
  },
  convertHeadString(val) {
   let orderBy;
   switch (val) {
    case "Логин":
     orderBy = "login";
     break;
    case "ФИО":
     orderBy = "name";
     break;
    case "Телефон":
     orderBy = "phone";
     break;
    case "Организация":
     orderBy = "company";
     break;
    case "Должность":
     orderBy = "position";
     break;
    case "Права доступа":
     orderBy = "userrole_id";
     break;
   }
   return orderBy;
  },
  setNull(obj) {
   Object.keys(obj).forEach((k) => (obj[k] = null));
  },
 },
 mounted() {
  this.$store.dispatch("getList");
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
.not-found {
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
}
.users-list-container {
 height: 100%;
 grid-gap: 20px;
 display: grid;
 grid-template-columns: 20% 1fr;
 grid-template-rows: 100%;
 margin: 0;
}
.search {
 display: grid;
 grid-gap: 20px;
}
.create-btn {
 margin-bottom: 20px;
}
.pages {
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 background-color: var.$grey;
 padding: 10px;
 border-radius: 16px;
 margin: 15px 0px 20px;
 width: 17%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 flex-direction: row;
 p {
  width: 26%;
 }
 .icon {
  transition: 100ms;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .icon:first-child {
  transform: rotate(180deg);
 }
 .icon:hover {
  cursor: pointer;
  border-radius: 8px;
  transition: 100ms;
  background-color: #8a9095;
 }
 input {
  border-style: hidden;
  background-color: var.$light;
  padding: 5px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 30%;
 }
}
input {
 width: fit-content;
}
.btns {
 width: 100%;
 display: grid;
 justify-content: end;
 grid-gap: 15px;
 justify-items: center;
 grid-template-columns: repeat(2, 1fr);
}
.form {
 display: grid;
 grid-gap: 15px;

 div {
  width: 100%;
  div {
   display: flex;
   width: 100%;
   justify-content: flex-end;
   align-items: center;
   margin-bottom: 20px;
  }
 }
 .input {
  width: 100%;
 }
 label {
  margin-right: 43px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
 }
}
button {
 color: white;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 border-style: hidden;
 padding: 16px 40px;
 background-color: var.$brand;
 border-radius: 8px;
 font-size: 16px;
}
section {
 width: 100%;
 height: 90%;
}
@media screen and (max-width: 1280px) {
 .pages {
  width: 20%;
 }
 .users-list-container {
  height: 100%;
  grid-gap: 20px;
  display: grid;
  grid-template-rows: 9% 1fr;
  grid-template-columns: 100%;
  margin: 0;
 }
 .search {
  width: 20%;
  grid-template-columns: repeat(2, 1fr);
 }
 .form {
  justify-items: center;
 }
 section {
  height: 84%;
 }
}
@media screen and (max-width: 1000px) {
 .pages {
  width: 40%;
 }
 .form {
  grid-template-columns: repeat(2, 1fr);
 }
 .btns {
  grid-template-columns: repeat(2, 1fr);
 }
}
@media screen and (max-width: 460px) {
 .table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
 .form {
  grid-template-columns: 1fr;
  grid-gap: 10px;
 }
 .search {
  width: 100%;
  padding: 0px 10px;
 }
 .pages {
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
 }
 section {
  height: 100%;
 }
}
</style>
