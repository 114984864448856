<template>
 <div class="account-container">
  <div class="con">
   <h1>Учётная запись</h1>
   <div class="account">
    <p><strong> Логин:</strong> {{ currentUser.email }}</p>
    <p><strong> ФИО:</strong> {{ currentUser.name }}</p>
    <p><strong>Телефон:</strong> {{ currentUser.phone }}</p>
    <p><strong>Организация:</strong> {{ currentUser.company }}</p>
    <p><strong>Должность:</strong> {{ currentUser.position }}</p>
    <p><strong>Права:</strong> {{ roleString }}</p>
   </div>
   <base-button title="Изменить" mode="full" @click="openPopup" />
   <base-popup v-if="popupIsOpen">
    <div class="form">
     <base-input
      @data="setLocalUser('email', $event)"
      label="Логин"
      :value="this.currentUser.email"
     />
     <base-input
      @data="setLocalUser('name', $event)"
      label="ФИО"
      :value="this.currentUser.name"
     />
     <base-input
      @data="setLocalUser('phone', $event)"
      label="Телефон"
      :value="this.currentUser.phone"
     />
     <base-input
      @data="setLocalUser('company', $event)"
      label="Организация"
      :value="this.currentUser.company"
     />
     <base-input
      @data="setLocalUser('position', $event)"
      label="Должность"
      :value="this.currentUser.position"
     />
     <div class="password">
      <base-input
       label="Старый пароль"
       :value="this.oldPassword"
       @data="validateOldPassword"
      />

      <base-input
       @data="setLocalUser('password', $event)"
       label="Новый пароль"
       :disabled="!this.validOldPassword"
       :value="this.currentUser.password"
      />
      <base-input
       @data="setLocalUser('password2', $event)"
       label="Подтверждение пароля"
       :disabled="!this.validOldPassword"
       :value="this.currentUser.password2"
      />
     </div>

     <div class="btns">
      <base-button title="Отмена" mode="flat" @click="closePopup" />
      <base-button
       title="Изменить"
       :mode="this.validLocalUser"
       @click="saveUser"
      />
     </div>
    </div>
   </base-popup>
  </div>
 </div>
</template>

<script>
import BaseButton from "../UI/BaseButton.vue";
import { debounce } from "vue-debounce";
export default {
 components: { BaseButton },
 data() {
  return {
   oldPassword: null,
   localUser: {
    name: null,
    email: null,
    phone: null,
    company: null,
    position: null,
    password: null,
    password2: null,
   },
   popupIsOpen: false,
  };
 },
 watch: {},
 methods: {
  openPopup() {
   this.popupIsOpen = true;
   this.localUser = structuredClone(this.currentUser);
  },
  closePopup() {
   this.popupIsOpen = false;
   this.oldPassword = null;
   this.$store.dispatch("validatePassword", {
    email: this.currentUser.email,
    password: null,
   });
  },
  saveUser() {
   this.closePopup();
   this.$store.dispatch("saveUser", {
    ...this.localUser,
   });
  },
  setLocalUser(field, event) {
   this.localUser[field] = event;
  },
 },
 computed: {
  validLocalUser() {
   for (let el in this.localUser) {
    if (this.localUser[el] === "" && el !== "password" && el !== "password2") {
     return "disabled";
    }
   }
   return "full";
  },
  validOldPassword() {
   if (this.$store.getters.getPasswordValidity) {
    return this.$store.getters.getPasswordValidity;
   }
   return false;
  },
  validateOldPassword() {
   const fn = debounce((val) => {
    this.oldPassword = val;
    this.$store.dispatch("validatePassword", {
     email: this.currentUser.email,
     password: this.oldPassword,
    });
   }, "400ms");
   return fn;
  },
  currentUser() {
   return structuredClone(this.$store.getters.getCurrentUser);
  },
  roleString() {
   const roles = this.$store.getters.getRoles;
   return roles.find((el) => el.userrole_id === this.currentUser.userrole_id)
    .name;
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
.account-container {
 display: flex;
 width: 100%;
 height: 100%;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 align-items: flex-start;
 .con {
  border-radius: 16px;
  padding: 20px;
  background-color: white;
 }
 h1 {
  margin-bottom: 30px;
 }
}
.password {
 padding: 10px;
 display: grid;
 grid-gap: 15px;
 background-color: var.$light;
 border-radius: 16px;
}
.form {
 display: grid;
 grid-gap: 15px;
}
.btns {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-gap: 15px;
 width: 100%;
 justify-items: center;
}
.account {
 display: grid;
 grid-gap: 10px;
 margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
 .account-container {
  padding: 5px;
  display: block;
 }
}
</style>
