<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M36.1356 18.6809H45.6549C46.3009 18.6809 46.8875 18.9446 47.3131 19.3701C47.7378 19.7949 48.0023 20.3823 48.0023 21.0283V38.7525C48.0023 39.3985 47.7386 39.9851 47.3131 40.4107C46.8883 40.8354 46.3009 41.0999 45.6549 41.0999H35.9922C36.0844 40.8907 36.1356 40.6599 36.1356 40.4171V39.82H45.6549C45.9475 39.82 46.2143 39.6998 46.4075 39.5059C46.6014 39.3119 46.7216 39.0459 46.7216 38.7533V21.0291C46.7216 20.7366 46.6014 20.4697 46.4075 20.2765C46.2135 20.0826 45.9475 19.9624 45.6549 19.9624H36.1356V18.6825V18.6809Z"
  />
  <path
   d="M37.7914 39.8181H40.2942V45.7759H37.1519V39.8181H37.7922H37.7914ZM39.0136 41.098H38.4309V44.496H39.0136V41.098Z"
  />
  <path
   d="M35.4956 46.7203H46.7211V45.7762H35.4956V44.4963H46.9119C47.21 44.4963 47.4817 44.619 47.6804 44.8169L47.682 44.8153C47.8792 45.0125 48.0018 45.2849 48.0018 45.5855V46.9102C48.0018 47.2075 47.8792 47.4792 47.682 47.678L47.6796 47.6804C47.4809 47.8775 47.2092 48.0002 46.9119 48.0002H35.4956V46.7203Z"
  />
  <path
   d="M36.1357 20.7668H44.8584C45.1445 20.7668 45.4042 20.8839 45.5917 21.0714C45.7793 21.2589 45.8963 21.5186 45.8963 21.8047V37.9742C45.8963 38.2603 45.7793 38.52 45.5917 38.7075C45.4042 38.895 45.1445 39.012 44.8584 39.012H36.1357V37.7322H44.6164V22.0467H36.1357V20.7668Z"
  />
  <path
   d="M36.5621 24.4791H40.8842C41.1783 24.4791 41.4452 24.5993 41.6383 24.7917C41.8315 24.9848 41.9509 25.2517 41.9509 25.5458V27.8074C41.9509 28.1 41.8306 28.366 41.6383 28.5592L41.6359 28.5616C41.4428 28.7547 41.1767 28.8741 40.8842 28.8741H36.5621C36.4106 28.8741 36.2664 28.8421 36.1357 28.7852V24.5705C36.2672 24.512 36.4114 24.4799 36.5621 24.4799V24.4791ZM40.671 25.759H36.7761V27.5935H40.671V25.759Z"
  />
  <path
   d="M18.3474 16H34.4287C35.0747 16 35.6613 16.2637 36.0869 16.6892C36.5116 17.114 36.7761 17.7014 36.7761 18.3474V40.4161C36.7761 41.0621 36.5124 41.6487 36.0869 42.0743C35.6621 42.499 35.0747 42.7635 34.4287 42.7635H18.3474C17.7014 42.7635 17.1148 42.4998 16.6892 42.0743C16.2645 41.6495 16 41.0621 16 40.4161V18.3474C16 17.7014 16.2637 17.1148 16.6892 16.6892C17.114 16.2645 17.7014 16 18.3474 16ZM34.4287 17.2799H18.3474C18.0548 17.2799 17.788 17.4001 17.5948 17.594C17.4009 17.788 17.2807 18.054 17.2807 18.3466V40.4153C17.2807 40.7079 17.4009 40.9747 17.5948 41.1679C17.7888 41.3618 18.0548 41.482 18.3474 41.482H34.4287C34.7212 41.482 34.9881 41.3618 35.1812 41.1679C35.3752 40.9739 35.4954 40.7079 35.4954 40.4153V18.3466C35.4954 18.054 35.3752 17.7872 35.1812 17.594C34.9873 17.4001 34.7212 17.2799 34.4287 17.2799Z"
  />
  <path
   d="M25.4569 41.4828H27.9598V45.7768H24.8174V41.4828H25.4577H25.4569ZM26.6791 42.7627H26.0965V44.4961H26.6791V42.7627Z"
  />
  <path
   d="M17.2807 46.7201H35.4954V45.776H17.2807V46.7201ZM35.6853 48H17.0899C16.7894 48 16.5169 47.8774 16.3198 47.6802C16.1226 47.4831 16 47.2106 16 46.91V45.5853C16 45.2848 16.1226 45.0123 16.3198 44.8151C16.5169 44.618 16.7894 44.4954 17.0899 44.4954H35.6853C35.9859 44.4954 36.2584 44.618 36.4555 44.8151C36.6527 45.0123 36.7753 45.2848 36.7753 45.5853V46.91C36.7753 47.2106 36.6527 47.4831 36.4555 47.6802C36.2584 47.8774 35.9859 48 35.6853 48Z"
  />
  <path
   d="M19.1438 18.1052H33.6319C33.918 18.1052 34.1777 18.2222 34.3652 18.4098C34.5527 18.5973 34.6698 18.857 34.6698 19.1431V39.6186C34.6698 39.9047 34.5527 40.1644 34.3652 40.3519C34.1777 40.5394 33.918 40.6565 33.6319 40.6565H19.1438C18.8577 40.6565 18.598 40.5394 18.4105 40.3519C18.223 40.1644 18.106 39.9047 18.106 39.6186V19.1431C18.106 18.857 18.223 18.5973 18.4105 18.4098C18.598 18.2222 18.8577 18.1052 19.1438 18.1052ZM33.3899 19.3851H19.3858V39.3766H33.3899V19.3851Z"
  />
  <path
   d="M23.4715 22.0964H29.3042C29.5984 22.0964 29.8652 22.2166 30.0584 22.409C30.2515 22.6021 30.3709 22.869 30.3709 23.1631V25.4247C30.3709 25.7173 30.2507 25.9833 30.0584 26.1765L30.056 26.1789C29.8628 26.372 29.5967 26.4914 29.3042 26.4914H23.4715C23.1774 26.4914 22.9105 26.3712 22.7173 26.1789C22.5242 25.9857 22.4048 25.7189 22.4048 25.4247V23.1631C22.4048 22.8722 22.5242 22.6061 22.7173 22.4122L22.7197 22.4098C22.9137 22.2166 23.1798 22.0972 23.4707 22.0972L23.4715 22.0964ZM29.0911 23.3763H23.6855V25.2108H29.0911V23.3763Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
