import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import BaseInput from "./components/UI/BaseInput.vue";
import BasePopup from "./components/UI/BasePopup.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import vueDebounce from "vue-debounce";
import ClickOutside from "./components/directives/ClickOutside";

const app = createApp(App);
app.component("base-input", BaseInput);
app.component("base-popup", BasePopup);
app.component("base-button", BaseButton);
app.use(store);
app.directive("debounce", vueDebounce({ lock: true }));
app.directive("click-outside", ClickOutside);
app.mount("#app");
