<template>
 <section v-if="this.$store.getters.getCurrentUser">
  <div class="container">
   <the-header />
   <the-side-bar @sidebarState="setSidebarState" @switchPage="switchPage" />
   <div class="content">
    <component :is="activePage" :windowWidth="windowWidth" />
   </div>
  </div>
 </section>
 <login-page v-else />
 <base-popup v-if="errorMessage">
  <div class="error">
   <h1>Ошибка</h1>
   <p>{{ errorMessage }}</p>
   <base-button
    title="Закрыть"
    mode="flat"
    @click="this.$store.dispatch('clearError')"
   />
  </div>
 </base-popup>
</template>

<script>
import UserAccount from "./components/pages/UserAccount.vue";
import OrganizationsList from "./components/pages/OrganizationsList";
import LoginPage from "./components/pages/LoginPage";
import UsersList from "./components/pages/UsersList";
import TheHeader from "./components/UI/TheHeader.vue";
import TheSideBar from "./components/UI/TheSideBar.vue";
export default {
 components: {
  TheSideBar,
  TheHeader,
  UsersList,
  LoginPage,
  OrganizationsList,
  UserAccount,
 },
 data() {
  return {
   activePage: null,
   sidebarState: "88px 1fr",
  };
 },
 computed: {
  windowWidth() {
   return window.screen.width;
  },
  errorMessage() {
   return this.$store.getters.getErrorMessage;
  },
  state() {
   return this.sidebarState;
  },
 },
 methods: {
  switchPage(name) {
   this.activePage = name;
  },
  setSidebarState(state) {
   if (state) {
    this.sidebarState = "304px 1fr";
   } else {
    this.sidebarState = "88px 1fr";
   }
  },
 },
 mounted() {
  this.$store.dispatch("getUser");
 },
};
</script>
<style lang="scss">
@use "./assets/variebles" as var;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.error {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 h1 {
  color: var.$red;
  margin-bottom: 20px;
 }
 p {
  margin-bottom: 20px;
 }
}

.content {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 background-color: transparent;
 grid-area: content;
}
.container {
 //  overflow: scroll;
 background-color: var.$light;
 transition: 200ms;
 height: auto;
 width: 100%;
 margin: 40px 80px 0px;

 grid-gap: 20px;
 display: grid;
 grid-template-areas:
  "sideBar header "
  "sideBar content";
 grid-template-columns: v-bind(state);
 grid-template-rows: 10% 1fr;
}
section {
 display: flex;
 background-color: var.$light;
 height: 100vh;
 width: 100%;
}
#app {
 width: 100vw;
 height: 100vh;
}
@media screen and (max-width: 1280px) {
 .content {
  height: auto;
 }
 .container {
  height: auto;
  margin: 15px 15px 0px;
 }
}
@media screen and (max-width: 1000px) {
 .content {
  height: auto;
 }
 .container {
  height: fit-content;
  overflow-y: scroll;
  margin: 15px 15px 0px;
 }
 section {
  height: fit-content;
 }
}

@media screen and (max-width: 600px) {
 section {
  height: 100vh;
 }
 .container {
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0;
  grid-gap: 0px;
 }
 .content {
  margin-top: 10px;
  height: 100%;
 }
}
// @media screen and (orientation: landscape) {
//  .container {
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   margin: 0;
//   grid-gap: 0px;
//  }
// }
</style>
