import { BASE_URL } from ".";
const usersModule = {
 state: {
  usersLoading: false,
  selectedOrg: null,
  usersList: [],
  pagesData: "",
  localpagesData: {
   currentPage: 1,
   perPage: 5,
  },
  searchOptions: {
   email: null,
   name: null,
   phone: null,
   company: null,
   position: null,
   userrole_id: -1,
   orderby: "",
   orderdir: "asc",
  },
 },
 getters: {
  getUsersLoading(state) {
   return state.usersLoading;
  },
  getPagesData(state) {
   return state.pagesData;
  },
  getUsersList(state) {
   return state.usersList;
  },
 },
 actions: {
  setSelectedOrg(context, payload) {
   context.commit("setSelectedOrg", payload);
  },
  setSearchOptions(context, payload) {
   context.commit("setSearchOptions", payload);
   context.dispatch("getList");
  },
  setPage(context, payload) {
   context.commit("setPage", payload);
   if (payload.fetch) {
    context.dispatch("getList");
   }
  },
  async delete(context, payload) {
   const response = await fetch(`${BASE_URL}/api/user/delete`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     user_id: payload,
    }),
   });
   const data = await response.json();
   context.dispatch("getList");
   if (data.status === "error") {
    context.commit("setError", data.message);
   }
  },
  async save(context, payload) {
   fetch(`${BASE_URL}/api/user/save`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.dispatch("getList");
     } else {
      context.commit("setError", data.message);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  async getList(context) {
   context.state.usersLoading = true;
   fetch(`${BASE_URL}/api/user/list`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...context.state.searchOptions,
     page: context.state.localpagesData.currentPage,
     numonpage: context.state.localpagesData.perPage,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     context.state.usersLoading = false;
     if (data.status === "success") {
      console.log(data);
      context.commit("setUsersList", data);
     } else {
      context.commit("setUsersList", null);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  async create(context, payload) {
   fetch(`${BASE_URL}/api/user/create`, {
    method: "POST",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     ...payload,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.dispatch("linkUser", {
       organization_id: context.state.selectedOrg,
       user_id: data.payload.user_id,
      });
      context.dispatch("getList");
     } else {
      alert(data.message);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
  async getRoles(context) {
   fetch(`${BASE_URL}/api/user/listroles?lazy=false`, {
    headers: {
     hash: localStorage.getItem("hash"),
    },
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.commit("setCurrenUserRights", data.payload);
     } else {
      alert(data.message);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
 },
 mutations: {
  setSelectedOrg(state, payload) {
   state.selectedOrg = payload;
  },
  setUsersList(state, payload) {
   if (payload) {
    state.usersList = payload.payload.results;

    state.pagesData = payload.payload.pager;
    state.pagesData.totalPages = Math.ceil(
     payload.payload.pager.total / payload.payload.pager.numOnPage
    );
   } else {
    state.usersList = [];
   }
  },
  setSearchOptions(state, payload) {
   state.searchOptions = payload;
  },
  setPage(state, payload) {
   state.localpagesData.currentPage = payload.currentPage;
   state.localpagesData.perPage = payload.perPage;
  },
 },
};

export default usersModule;
