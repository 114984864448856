export default {
 beforeMount(el, func) {
  el.clickOutsideEvent = function (event) {
   if (!(el === event.target || el.contains(event.target))) {
    func.value(event);
   }
  };
  document.addEventListener("click", el.clickOutsideEvent);
 },
 unmounted(el) {
  document.removeEventListener("click", el.clickOutsideEvent);
 },
};
