<template>
 <div class="org-conatiner">
  <div class="search" v-if="this.windowWidth > 1280">
   <base-button
    w="100"
    title="Создать"
    mode="full"
    @click="(popupIsOpen = true), (isEdit = false)"
   />
   <base-input
    w="100"
    mode="select"
    :defaultOption="
     this.searchOptions.orgtype
      ? this.convertOrgType(this.searchOptions.orgtype, 2)
      : 'Без типа'
    "
    @selected="this.searchOptions.orgtype = this.convertOrgType($event, 1)"
    :options="['Без типа', 'Клиент', 'Сервисная', 'Проверяющий орган']"
   />
   <base-input
    w="100"
    placeholder="Название"
    :value="this.searchOptions.name"
    @data="setSearchOptions('name', $event)"
   />

   <base-input
    w="100"
    placeholder="ИНН"
    :value="this.searchOptions.inn"
    @data="setSearchOptions('inn', $event)"
   />
   <base-input
    w="100"
    placeholder="КПП"
    :value="this.searchOptions.kpp"
    @data="setSearchOptions('kpp', $event)"
   />
   <base-input
    w="100"
    placeholder="Руководитель"
    :value="this.searchOptions.head"
    @data="setSearchOptions('head', $event)"
   />
   <base-input
    w="100"
    placeholder="Телефон"
    :value="this.searchOptions.phone"
    @data="setSearchOptions('phone', $event)"
   />
   <base-input
    w="100"
    placeholder="Регион"
    :value="this.searchOptions.region"
    @data="setSearchOptions('region', $event)"
   />
   <base-input
    w="100"
    placeholder="Город"
    :value="this.searchOptions.city"
    @data="setSearchOptions('city', $event)"
   />
   <base-input
    w="100"
    placeholder="Адрес"
    :value="this.searchOptions.address"
    @data="setSearchOptions('address', $event)"
   />
  </div>
  <div class="search" v-else>
   <base-button
    mode="full"
    title="Создать"
    w="100"
    @click="popupIsOpen = true"
   />
   <base-button
    mode="flat"
    title="Фильтры"
    w="100"
    @click="this.searchPopupIsOpen = true"
   />

   <base-popup v-if="this.searchPopupIsOpen">
    <div class="form-filters">
     <base-input
      w="100"
      mode="select"
      :value="this.orgTypeRu"
      :defaultOption="
       this.searchOptions.orgtype
        ? this.convertOrgType(this.searchOptions.orgtype, 2)
        : 'Без типа'
      "
      @selected="
       this.searchOptions.orgtype = this.convertOrgType($event, 1);
       this.orgTypeRu = $event;
      "
      :options="['Без типа', 'Клиент', 'Сервисная', 'Проверяющий орган']"
     />
     <base-input
      w="100"
      placeholder="Название"
      :value="this.searchOptions.name"
      @data="setSearchOptions('name', $event)"
     />

     <base-input
      w="100"
      placeholder="ИНН"
      :value="this.searchOptions.inn"
      @data="setSearchOptions('inn', $event)"
     />
     <base-input
      w="100"
      placeholder="КПП"
      :value="this.searchOptions.kpp"
      @data="setSearchOptions('kpp', $event)"
     />
     <base-input
      w="100"
      placeholder="Руководитель"
      :value="this.searchOptions.head"
      @data="setSearchOptions('head', $event)"
     />
     <base-input
      w="100"
      placeholder="Телефон"
      :value="this.searchOptions.phone"
      @data="setSearchOptions('phone', $event)"
     />
     <base-input
      w="100"
      placeholder="Регион"
      :value="this.searchOptions.region"
      @data="setSearchOptions('region', $event)"
     />
     <base-input
      w="100"
      placeholder="Город"
      :value="this.searchOptions.city"
      @data="setSearchOptions('city', $event)"
     />
     <base-input
      w="100"
      placeholder="Адрес"
      :value="this.searchOptions.address"
      @data="setSearchOptions('address', $event)"
     />
    </div>
    <div class="btn-close">
     <base-button
      title="Закрыть"
      mode="flat"
      @click="this.searchPopupIsOpen = false"
     />
    </div>
   </base-popup>
  </div>
  <div class="table-container">
   <section v-if="this.list.length > 0">
    <table-list
     :mode="this.windowWidth > 460 ? 1 : false"
     :displayItems="['name', 'inn', 'orgtype']"
     :loading="this.loading"
     :orderBy="this.orderbyRu"
     :tableHead="this.windowWidth > 460 ? tableHead : tableHeadMobile"
     :tableList="list"
     @delete="deleteOrg"
     @edit="editOrg"
     @sort="setSort"
    />
   </section>
   <section class="not-found" v-else>Ничего не найдено</section>
   <div class="pages" v-if="list.length > 0">
    <div class="icon">
     <img src="../../assets/icons/arrow.svg" @click="switchPage(2)" />
    </div>
    <p>{{ pagesData.page }} / {{ pagesData.totalPages }}</p>
    <input v-debounce:300ms="setPerPage" :value="this.localPagesData.perPage" />
    <div class="icon">
     <img src="../../assets/icons/arrow.svg" @click="switchPage(1)" />
    </div>
   </div>
  </div>
 </div>
 <base-popup v-if="popupIsOpen && isEdit">
  <div class="form">
   <base-input
    label="Название"
    :value="this.createOrg.name"
    @data="this.createOrg.name = $event"
   />
   <base-input
    label="ИНН"
    :value="this.createOrg.inn"
    @data="this.createOrg.inn = $event"
   />
   <base-input
    label="КПП"
    :value="this.createOrg.kpp"
    @data="this.createOrg.kpp = $event"
   />
   <base-input
    label="Руководитель"
    :value="this.createOrg.head"
    @data="this.createOrg.head = $event"
   />
   <base-input
    label="Телефон"
    :value="this.createOrg.phone"
    @data="this.createOrg.phone = $event"
   />
   <base-input
    label="Регион"
    :value="this.createOrg.region"
    @data="this.createOrg.region = $event"
   />
   <base-input
    label="Город"
    :value="this.createOrg.city"
    @data="this.createOrg.city = $event"
   />
   <base-input
    label="Адрес"
    :value="this.createOrg.address"
    @data="this.createOrg.address = $event"
   />
   <base-input
    label="Тип"
    mode="select"
    :defaultOption="
     this.createOrg.orgtype
      ? this.convertOrgType(this.createOrg.orgtype, 2)
      : 'Без типа'
    "
    @selected="this.createOrg.orgtype = this.convertOrgType($event, 1)"
    :options="['Без типа', 'Клиент', 'Сервисная', 'Проверяющий орган']"
   />
   <div class="btns">
    <base-button title="Отмена" mode="flat" @click="popupIsOpen = false" />
    <base-button title="Изменить" mode="full" @click="editOrg1" v-if="isEdit" />
   </div>
  </div>
 </base-popup>
 <create-org-popup
  v-if="popupIsOpen && !isEdit"
  @close="this.popupIsOpen = false"
 />
</template>

<script>
import TableList from "../UI/TableList.vue";
import { debounce } from "vue-debounce";
import CreateOrgPopup from "../CreateOrgPopup.vue";
import PagesMixin from "../mixins/PagesMixin";
import SetStortMixin from "../mixins/SetSortMixin";
export default {
 mixins: [PagesMixin, SetStortMixin],
 props: ["windowWidth"],
 components: { TableList, CreateOrgPopup },
 data() {
  return {
   orgTypeRu: null,
   localPagesData: {
    currentPage: 1,
    perPage: 5,
   },
   isEdit: false,
   orderbyRu: null,
   searchPopupIsOpen: false,

   searchOptions: {
    name: null,
    inn: null,
    kpp: null,
    head: null,
    phone: null,
    region: null,
    city: null,
    address: null,
    orgtype: null,
    orderby: null,
    orderdir: "asc",
   },
   createOrg: {
    organization_id: null,
    name: null,
    inn: null,
    kpp: null,
    head: null,
    phone: null,
    region: null,
    city: null,
    address: null,
    orgtype: null,
   },
   popupIsOpen: false,
   tableHead: [
    {
     field: "name",
     title: "Название",
     sort: true,
    },
    {
     field: "inn",
     title: "ИНН",
     sort: true,
    },
    {
     field: "orgtype",
     title: "Тип",
     sort: true,
    },
    {
     field: "kpp",
     title: "КПП",
     sort: true,
    },
    {
     field: "head",
     title: "Глава",
     sort: true,
    },
    {
     field: "phone",
     title: "Телефон",
     sort: true,
    },
    {
     field: "region",
     title: "Регион",
     sort: true,
    },
    {
     field: "city",
     title: "Город",
     sort: true,
    },
    {
     field: "address",
     title: "Адрес",
     sort: true,
    },
   ],
   tableHeadMobile: [
    {
     field: "name",
     title: "Название",
     sort: true,
    },
    {
     field: "inn",
     title: "ИНН",
     sort: true,
    },
    {
     field: "orgtype",
     title: "Тип",
     sort: true,
    },
   ],
  };
 },
 watch: {
  localPagesData: {
   handler(newValue) {
    if (!newValue.page) {
     newValue.page = 5;
    }
    this.$store.dispatch("setOrgPage", { ...newValue, fetch: true });
   },
   deep: true,
  },
  searchOptions: {
   handler(newValue) {
    this.$store.dispatch("setOrgPage", { currentPage: 1, numonpage: 5 });
    this.$store.dispatch("setOrgSearchOptions", { ...newValue, fetch: true });
   },
   deep: true,
  },
 },
 mounted() {
  this.$store.dispatch("getOrgList", {
   name: null,
   currentPage: 1,
   numonpage: 5,
  });
  this.$store.dispatch("setOrgSearchOptions", { ...this.searchOptions });
  this.$store.dispatch("setOrgPage", { ...this.localPagesData });
 },
 methods: {
  editOrg(item) {
   this.isEdit = true;
   const arr = item.list([
    "organization_id",
    "name",
    "inn",
    "kpp",
    "head",
    "phone",
    "region",
    "city",
    "address",
   ]);
   let i = 0;
   for (let el in this.createOrg) {
    this.createOrg[el] = arr[i];
    i++;
   }
   this.createOrg.orgtype = this.convertOrgType(item.orgtype, 1);
   this.popupIsOpen = true;
  },
  editOrg1() {
   this.$store.dispatch("saveOrg", this.createOrg);
   this.isEdit = false;
   this.popupIsOpen = false;
  },
  deleteOrg(item) {
   this.$store.dispatch("deleteOrg", item.organization_id);
  },

  convertOrgType(val, direction) {
   if (this.types) {
    if (direction === 1) {
     const item = this.types.find((el) => el.name === val);
     if (item) {
      return item.identity;
     }
     return null;
    } else {
     const item = this.types.find((el) => el.identity === val);
     if (item) {
      return item.name;
     }
     return null;
    }
   }
  },
 },
 computed: {
  validCreateOrg() {
   for (let el in this.createOrg) {
    if (!this.createOrg[el] && el !== "organization_id") {
     return "disabled";
    }
   }
   return "full";
  },
  pagesData() {
   return this.$store.getters.getOrgPagesData;
  },
  loading() {
   return this.$store.getters.getOrgLoading;
  },
  setSearchOptions() {
   const fn = debounce((target, val) => {
    this.searchOptions[target] = val;
   }, "400ms");
   return fn;
  },
  types() {
   return this.$store.getters.getOrgTypes;
  },
  list() {
   const Olist = structuredClone(this.$store.getters.getOrgList);
   for (let el of Olist) {
    el.id = el.organization_id;
    el.list = function (fields) {
     let arr = [];
     for (let field of fields) {
      arr.push(this[field]);
     }
     return arr;
    };
   }
   for (let el of Olist) {
    el.orgtype = this.convertOrgType(el.orgtype, 2);
   }
   return Olist;
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
.not-found {
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
}
.pages {
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 background-color: var.$grey;
 padding: 10px;
 border-radius: 16px;
 margin: 15px 0px 20px;
 width: 222px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 flex-direction: row;
 p {
  width: 26%;
 }
 .icon {
  transition: 100ms;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .icon:first-child {
  transform: rotate(180deg);
 }
 .icon:hover {
  cursor: pointer;
  border-radius: 8px;
  transition: 100ms;
  background-color: #8a9095;
 }
 input {
  border-style: hidden;
  background-color: var.$light;
  padding: 5px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 30%;
 }
}
.search {
 height: fit-content;
 grid-template-rows: repeat(10, 1fr);
 display: grid;
 grid-gap: 15px;
}
.form {
 display: grid;
 grid-gap: 15px;
 .btns {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
 }
}
.org-conatiner {
 height: 100%;
 display: grid;
 grid-template-columns: 15% 1fr;
 grid-gap: 20px;
}
section {
 width: 100%;
 height: 90%;
}
@media screen and (max-width: 1280px) {
 .btn-close {
  width: 100%;
  display: flex;
  justify-content: center;
 }
 .form-filters {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
 }
 .org-conatiner {
  height: 100%;
  grid-gap: 20px;
  display: grid;
  grid-template-rows: 9% 1fr;
  grid-template-columns: 100%;
  margin: 0;
 }
 .search {
  width: 20%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
 }
 section {
  height: 84%;
 }
}

@media screen and (max-width: 1000px) {
 .pages {
  width: 40%;
 }
 .form {
  grid-template-columns: repeat(2, 1fr);
 }
 .btns {
  grid-template-columns: repeat(2, 1fr);
 }
}

@media screen and (max-width: 460px) {
 section {
  height: 100%;
 }
 .form {
  grid-gap: 10px;
 }
 .form-filters {
  grid-template-columns: 1fr;
  grid-gap: 10px;
 }
 .search {
  width: 100%;
  padding: 0px 10px;
 }
 .pages {
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
 }
 .table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
}
</style>
