<template>
 <Teleport to="body">
  <div class="out">
   <div class="in">
    <slot></slot>
   </div>
  </div>
 </Teleport>
</template>

<script>
export default {
 props: ["w"],
 computed: {
  width() {
   if (this.w) {
    return this.w;
   }
   return "auto";
  },
 },
};
</script>
<style lang="scss" scoped>
.out {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100vh;
 position: fixed;
 background-color: rgba(0, 0, 0, 0.3);
 top: 0;
 left: 0;
 .in {
  width: v-bind(width);
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  padding: 48px;
  box-shadow: 0px 10px 20px 0px #0000001a;
  border-radius: 8px;
 }
}
@media screen and (max-width: 460px) {
 .out {
  .in {
   padding: 20px;
  }
 }
}
</style>
