<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M18.518 19.9807H25.983V20.1533H25.151V22.2713C25.129 22.252 25.1079 22.2323 25.0872 22.2117C24.8504 21.9749 24.7031 21.6475 24.7031 21.2868V21.2606H18.518C18.178 21.2606 17.8689 21.3999 17.6443 21.6245C17.4196 21.8492 17.2804 22.1587 17.2804 22.4983V45.4818C17.2804 45.8219 17.4196 46.1309 17.6443 46.3556C17.8689 46.5802 18.1785 46.7195 18.518 46.7195H45.482C45.822 46.7195 46.1311 46.5802 46.3557 46.3556C46.5804 46.1309 46.7196 45.8214 46.7196 45.4818V22.4983C46.7196 22.1582 46.5804 21.8492 46.3557 21.6245C46.1311 21.3999 45.8215 21.2606 45.482 21.2606H39.1252V21.2868C39.1252 21.647 38.9779 21.9744 38.7411 22.2112C38.7101 22.2422 38.6778 22.2717 38.6435 22.2994V20.1528H37.8453V19.9802H45.482C46.1751 19.9802 46.8045 20.2635 47.2609 20.7194C47.7167 21.1752 48 21.8051 48 22.4983V45.4818C48 46.175 47.7167 46.8044 47.2609 47.2607C46.805 47.7166 46.1751 47.9999 45.482 47.9999H18.518C17.8249 47.9999 17.1955 47.7166 16.7391 47.2607C16.2833 46.8049 16 46.175 16 45.4818V22.4983C16 21.8051 16.2833 21.1757 16.7391 20.7194C17.195 20.2635 17.8249 19.9802 18.518 19.9802V19.9807Z"
  />
  <path
   d="M19.9935 23.7834H44.0065C44.3822 23.7834 44.7241 23.9373 44.9717 24.1854H44.974C45.2221 24.4335 45.376 24.7758 45.376 25.1529V44.0375C45.376 44.4145 45.2221 44.7574 44.974 45.005H44.9717C44.7236 45.2531 44.3822 45.4069 44.0065 45.4069H19.9935C19.6178 45.4069 19.2759 45.2531 19.0283 45.005H19.026C18.7779 44.7569 18.624 44.4145 18.624 44.0375V25.1529C18.624 24.7758 18.7779 24.433 19.026 24.1854H19.0283C19.2764 23.9373 19.6178 23.7834 19.9935 23.7834ZM44.0065 25.0633H19.9935C19.9686 25.0633 19.9457 25.0732 19.9292 25.0887L19.9306 25.0901C19.9142 25.1065 19.9039 25.129 19.9039 25.1529V44.0375C19.9039 44.0614 19.9142 44.0839 19.9306 44.1003L19.9292 44.1017C19.9457 44.1177 19.9682 44.127 19.9935 44.127H44.0065C44.0314 44.127 44.0543 44.1172 44.0708 44.1017L44.0693 44.1003C44.0858 44.0839 44.0961 44.0614 44.0961 44.0375V25.1529C44.0961 25.129 44.0858 25.1065 44.0693 25.0901L44.0708 25.0887C44.0543 25.0727 44.0318 25.0633 44.0065 25.0633Z"
  />
  <path
   d="M26.0121 19.3116H28.0142C28.1503 18.4768 28.548 17.7297 29.1201 17.1575C29.8354 16.4423 30.8235 16 31.9144 16C33.0058 16 33.9935 16.4423 34.7087 17.1575C35.2809 17.7297 35.6786 18.4768 35.8146 19.3116H37.8167C38.1774 19.3116 38.5047 19.4588 38.7416 19.6957C38.9784 19.9325 39.1257 20.2604 39.1257 20.6205V21.6284C39.1257 21.9886 38.9784 22.316 38.7416 22.5528C38.5047 22.7896 38.1774 22.9369 37.8172 22.9369H26.0126C25.6524 22.9369 25.3246 22.7896 25.0877 22.5528C24.8509 22.316 24.7036 21.9886 24.7036 21.6279V20.6201C24.7036 20.2594 24.8509 19.9321 25.0877 19.6952C25.3246 19.4584 25.6519 19.3111 26.0126 19.3111L26.0121 19.3116ZM28.6028 20.5915H26.0121C26.0046 20.5915 25.9976 20.5947 25.9924 20.6004C25.9868 20.606 25.9835 20.613 25.9835 20.6201V21.6279C25.9835 21.635 25.9868 21.642 25.9924 21.6476C25.998 21.6533 26.0051 21.6565 26.0121 21.6565H37.8167C37.8237 21.6565 37.8308 21.6528 37.8364 21.6472C37.842 21.6415 37.8458 21.6345 37.8458 21.6275V20.6196C37.8458 20.6126 37.842 20.6055 37.8369 20.5999C37.8313 20.5943 37.8242 20.591 37.8172 20.591H34.5867V19.9508C34.5867 19.2131 34.2875 18.5448 33.8045 18.0617C33.3209 17.5782 32.6531 17.2794 31.9154 17.2794C31.1776 17.2794 30.5093 17.5786 30.0262 18.0617C29.5427 18.5452 29.244 19.2131 29.244 19.9508V20.591H28.6038L28.6028 20.5915Z"
  />
  <path
   d="M23.0062 27.7817H28.0761C28.2824 27.7817 28.4691 27.8657 28.6046 28.0008C28.7397 28.1358 28.8237 28.323 28.8237 28.5293V31.0619C28.8237 31.2682 28.7397 31.4549 28.6046 31.5904C28.4696 31.7255 28.2824 31.8095 28.0761 31.8095H23.0062C22.8004 31.8095 22.6132 31.7255 22.4777 31.5904C22.3426 31.4554 22.2587 31.2682 22.2587 31.0619V28.5293C22.2587 28.3234 22.3426 28.1363 22.4777 28.0008C22.6128 27.8657 22.7999 27.7817 23.0062 27.7817ZM27.5433 29.0616H23.5386V30.5286H27.5433V29.0616Z"
  />
  <path
   d="M31.9144 20.9558C32.5356 20.9558 33.0391 20.4523 33.0391 19.8312C33.0391 19.2101 32.5356 18.7065 31.9144 18.7065C31.2933 18.7065 30.7898 19.2101 30.7898 19.8312C30.7898 20.4523 31.2933 20.9558 31.9144 20.9558Z"
  />
  <path
   d="M22.8989 37.9875H27.3694L28.8678 39.7922L32.7764 31.8169L36.2142 38.7346H41.1011V40.0145H35.4244L32.783 34.6993L29.145 42.1217L26.7756 39.2673H22.8989V37.9875Z"
  />
  <path
   d="M22.8988 39.2672C23.2524 39.2672 23.539 38.9806 23.539 38.627C23.539 38.2734 23.2524 37.9868 22.8988 37.9868C22.5453 37.9868 22.2587 38.2734 22.2587 38.627C22.2587 38.9806 22.5453 39.2672 22.8988 39.2672Z"
  />
  <path
   d="M41.1015 40.015C41.455 40.015 41.7417 39.7284 41.7417 39.3748C41.7417 39.0212 41.455 38.7346 41.1015 38.7346C40.7479 38.7346 40.4613 39.0212 40.4613 39.3748C40.4613 39.7284 40.7479 40.015 41.1015 40.015Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
