<template>
 <div>
  <div class="input-container" v-if="!mode">
   <label v-if="this.label">{{ label }}</label>
   <input
    :class="{ invalid: this.invalid }"
    :disabled="this.disabled ? this.disabled : false"
    :type="this.type"
    :data-id="this.id"
    @input="onInput"
    :value="value"
    :placeholder="this.placeholder"
   />
  </div>
  <div
   class="select-container"
   v-if="mode === 'select'"
   v-click-outside="close"
  >
   <label v-if="this.label">{{ label }}</label>
   <div class="con">
    <div class="select" @click="openSelect">
     {{ selectedOption
     }}<img
      src="../../assets/icons/icon-arrow.svg"
      :class="{ open: selectIsOpen }"
     />
    </div>
    <div class="select-item-container" :class="{ open: selectIsOpen }">
     <div class="scroll sl">
      <div
       class="select-item"
       v-for="item in options"
       :key="item"
       @click="selectItem"
      >
       {{ item }}
      </div>
     </div>
    </div>
   </div>
  </div>

  <div
   class="select-container"
   v-if="mode === 'select-search'"
   v-click-outside="close"
  >
   <Teleport to="body">
    <create-org-popup
     v-if="this.createIsOpen"
     @close="this.createIsOpen = false"
    />
   </Teleport>

   <label v-if="this.label">{{ label }}</label>
   <div class="con">
    <div class="select" @click="openSelect">
     {{ selectedOption
     }}<img
      src="../../assets/icons/icon-arrow.svg"
      :class="{ open: selectIsOpen }"
     />
    </div>

    <div class="select-item-container sr" :class="{ open: selectIsOpen }">
     <div class="search">
      <div class="input">
       <input
        id="input"
        @input="setSearchOptions($event)"
        placeholder="Поиск"
        class="search"
       />
       <div class="icon" @click="this.createIsOpen = true">
        <img src="../../assets/icons/create.svg" />
       </div>
      </div>
     </div>
     <div class="scroll" v-if="this.searchRequest">
      <div
       class="select-item"
       v-for="item in searchList"
       :key="item"
       @click="selectItem($event), (this.orgId = item.organization_id)"
      >
       {{ item.name }}
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>
<script>
import CreateOrgPopup from "../CreateOrgPopup.vue";
import { debounce } from "vue-debounce";
export default {
 components: { CreateOrgPopup },
 emits: ["data", "selected", "id"],
 props: [
  "type",
  "label",
  "mode",
  "options",
  "value",
  "defaultOption",
  "w",
  "userId",
  "placeholder",
  "disabled",
  "invalid",
 ],
 data() {
  return {
   orgId: null,
   createIsOpen: false,
   searchRequest: null,
   selectedOption: this.value ? this.value : "Не выбрано",
   selectIsOpen: false,
  };
 },
 watch: {
  searchRequest() {
   if (this.searchRequest) {
    this.$store.dispatch("setOrgSearchOptions", {
     name: this.searchRequest,
     fetch: true,
    });
   } else {
    this.$store.dispatch("setOrgSearchOptions", { name: null, fetch: true });
   }
  },
 },
 methods: {
  close() {
   this.selectIsOpen = false;
  },
  onInput(event) {
   this.$emit("data", event.target.value);
  },
  openSelect() {
   this.selectIsOpen = !this.selectIsOpen;
  },
  selectItem(event) {
   this.selectedOption = event.target.innerHTML;
   this.selectIsOpen = false;
   this.$emit("selected", event.target.innerHTML);
  },
 },
 computed: {
  searchList() {
   return this.$store.getters.getOrgList;
  },
  setSearchOptions() {
   const fn = debounce((val) => {
    this.searchRequest = val.target.value;
   }, "400ms");
   return fn;
  },
  width() {
   if (this.w) {
    return `${this.w}%`;
   }
   return "50%";
  },
 },
 mounted() {
  if (this.mode === "select-search") {
   this.$store.dispatch("setOrgPage", {
    currentPage: 1,
    perPage: 5000,
    fetch: true,
   });
  }
 },
};
</script>
<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;

::-webkit-scrollbar {
 width: 14px;
}

::-webkit-scrollbar-track {
 background: white;
 border-top-right-radius: 10px;
 border-bottom-right-radius: 10px;
}

::-webkit-scrollbar-thumb {
 border: 5px solid rgba(0, 0, 0, 0);
 background-clip: padding-box;
 border-radius: 9999px;
 background-color: var.$grey;
}

.scroll {
 margin-top: 10px;
 max-height: 200px;
 min-height: 0%;
 overflow-y: scroll;
}
.scroll.sl {
 margin: 0px;
}
.search {
 grid-gap: 10px;
 display: grid;
 grid-template-columns: 1fr;

 .input {
  margin-right: 8px;
  border-radius: 8px;
  display: flex;
  padding: 0px 0px 0px 16px;

  border: 1px solid var.$brand;
  .icon {
   padding: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 0px 6px 6px 0px;
   //    background-color: var.$brand;
  }
  .icon:hover {
   cursor: pointer;
   transition: 200ms;
   //    background-color: #2595c5;
   img {
    fill: var.$grey;
   }
  }
 }
 #input {
  margin: 16px 0px 16px;
  padding: 0;
  border-radius: 0;
  border-color: transparent;
  width: 100%;
  margin: 0;
 }
}
.input-container {
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.select-container {
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
img {
 transition: 200ms;
 transform: rotate(0deg);
}
img.open {
 transition: 200ms;
 transform: rotate(180deg);
}
.con {
 width: v-bind(width);
 position: relative;
}

.select-item-container.open {
 transition: all 200ms;
 height: fit-content;
 overflow: hidden;
 padding: 10px 2px 10px 10px;
 overflow-y: hidden;
}

.select-item-container {
 transition: 200ms;
 height: 0;
 overflow: hidden;
 top: 110%;
 border-radius: 16px;
 width: 100%;
 position: absolute;
 background-color: white;
 box-shadow: 0px 10px 20px 0px #0000001a;
 padding: 0px;
 .select-item {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border: 1px solid var.$brand;
  border-radius: 8px;
  margin-bottom: 10px;
 }

 .select-item:last-child {
  margin-bottom: 0px;
 }
 .select-item:hover {
  cursor: pointer;
  transition: 100ms;
  background-color: #29abe325;
 }
}

label {
 width: 25%;
 margin-right: 43px;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
}
input,
.select {
 width: v-bind(width);
 border: 1px solid var.$brand;
 outline: none;
 border-radius: 8px;
 padding: 16px;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 font-weight: 400;
}
input:focus {
 border-color: var.$green;
}
.select:hover {
 cursor: pointer;
 transition: 100ms;
 background-color: #29abe325;
}
.select {
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin: 0;
}
.select-item-container.sr {
 z-index: 99999;
 overflow-y: hidden;
}
input:disabled {
 border-color: var.$grey;
}
input.invalid {
 border-color: var.$red;
}
</style>
