<template>
 <svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M18.9842 33.2024C18.965 32.9963 18.9512 32.7958 18.9421 32.6007C18.932 32.3736 18.9265 32.173 18.9265 32C18.9265 29.554 19.6106 27.2088 20.8386 25.1932C22.0657 23.1795 23.8359 21.4972 26.0108 20.3745L26.5959 21.5091C24.6335 22.5219 23.0355 24.0403 21.9283 25.858C20.8221 27.673 20.2067 29.7893 20.2067 32C20.2067 32.2088 20.2104 32.391 20.2177 32.5458C20.226 32.7326 20.2388 32.913 20.2544 33.087L18.9842 33.2024Z"
  />
  <path
   d="M34.1217 44.9104C33.7636 44.969 33.412 45.0121 33.0686 45.0405C32.7133 45.0698 32.3598 45.0835 32.0109 45.0835C31.586 45.0835 31.1492 45.0606 30.7023 45.0157C30.271 44.9727 29.8387 44.9068 29.4065 44.8188L29.6565 43.5688C30.0402 43.6467 30.4312 43.7062 30.8277 43.7456C31.2087 43.7841 31.6034 43.8033 32.0109 43.8033C32.3351 43.8033 32.6537 43.7905 32.9688 43.7648C33.2948 43.7383 33.6116 43.6998 33.9175 43.6504L34.1226 44.9104H34.1217Z"
  />
  <path
   d="M37.902 20.3206C40.108 21.4332 41.9057 23.1191 43.1511 25.142C44.3974 27.1667 45.0925 29.5312 45.0925 32C45.0925 32.1731 45.087 32.3746 45.0769 32.6026C45.0677 32.7986 45.054 32.9991 45.0348 33.2061L43.7646 33.0907C43.7811 32.9158 43.793 32.7345 43.8022 32.5477C43.8095 32.3929 43.8132 32.2107 43.8132 32.001C43.8132 29.7729 43.1859 27.6402 42.0613 25.8132C40.9359 23.9854 39.315 22.4643 37.3269 21.4616L37.902 20.3215V20.3206Z"
  />
  <path
   d="M41.8324 34.4827C42.7554 34.4827 43.5915 34.8572 44.1968 35.4625C44.8021 36.0678 45.1767 36.9039 45.1767 37.827C45.1767 38.7501 44.8021 39.5861 44.1968 40.1915C43.5915 40.7968 42.7554 41.1713 41.8324 41.1713C40.9093 41.1713 40.0732 40.7968 39.4679 40.1915C38.8626 39.5861 38.488 38.7501 38.488 37.827C38.488 36.9039 38.8626 36.0678 39.4679 35.4625C40.0732 34.8572 40.9093 34.4827 41.8324 34.4827ZM43.2921 36.3673C42.9184 35.9937 42.402 35.7629 41.8324 35.7629C41.2618 35.7629 40.7463 35.9937 40.3727 36.3673C39.999 36.7409 39.7683 37.2574 39.7683 37.827C39.7683 38.3975 39.999 38.9131 40.3727 39.2867C40.7463 39.6603 41.2628 39.8911 41.8324 39.8911C42.4029 39.8911 42.9184 39.6603 43.2921 39.2867C43.6657 38.9131 43.8965 38.3966 43.8965 37.827C43.8965 37.2565 43.6657 36.7409 43.2921 36.3673Z"
  />
  <path
   d="M43.89 41.4998C44.9312 41.8652 45.8451 42.5008 46.5475 43.3204C47.2425 44.1308 47.7325 45.1253 47.9358 46.2205C48.0685 46.933 47.9989 47.3561 47.683 47.6628C47.3753 47.9614 46.9934 47.9998 46.4321 47.9998H37.107V47.9971C36.6052 47.9962 36.248 47.9476 35.9623 47.6537C35.6784 47.3616 35.6024 46.9605 35.7141 46.3048C35.9046 45.1921 36.3918 44.1802 37.0905 43.3524C37.7966 42.5172 38.7215 41.8707 39.7773 41.4998L40.0566 41.4019L40.3121 41.553C40.5365 41.6848 40.7819 41.7892 41.0429 41.8606C41.2938 41.9293 41.5594 41.966 41.8332 41.966C42.107 41.966 42.3726 41.9293 42.6235 41.8606C42.8845 41.7892 43.1299 41.6848 43.3543 41.553L43.6097 41.4019L43.889 41.4998H43.89ZM45.5777 44.15C45.0777 43.5667 44.4449 43.1015 43.726 42.803C43.4815 42.9238 43.2242 43.0227 42.9586 43.0951C42.5997 43.1931 42.2224 43.2453 41.8332 43.2453C41.444 43.2453 41.0667 43.1931 40.7077 43.0951C40.4412 43.0227 40.1848 42.9247 39.9403 42.803C39.2105 43.1061 38.5695 43.5813 38.0649 44.1775C37.5108 44.8332 37.1244 45.6363 36.9733 46.5191C36.9339 46.6647 36.988 46.7215 37.1061 46.7215L46.4312 46.7187C46.6308 46.7187 46.7233 46.6784 46.6803 46.4495C46.5191 45.5832 46.1299 44.7947 45.5768 44.1491L45.5777 44.15Z"
  />
  <path
   d="M22.1683 34.4827C23.0914 34.4827 23.9275 34.8572 24.5328 35.4625C25.1381 36.0678 25.5126 36.9039 25.5126 37.827C25.5126 38.7501 25.1381 39.5861 24.5328 40.1915C23.9275 40.7968 23.0914 41.1713 22.1683 41.1713C21.2452 41.1713 20.4091 40.7968 19.8038 40.1915C19.1985 39.5861 18.824 38.7501 18.824 37.827C18.824 36.9039 19.1985 36.0678 19.8038 35.4625C20.4091 34.8572 21.2452 34.4827 22.1683 34.4827ZM23.628 36.3673C23.2544 35.9937 22.7379 35.7629 22.1683 35.7629C21.5978 35.7629 21.0822 35.9937 20.7086 36.3673C20.335 36.7409 20.1042 37.2574 20.1042 37.827C20.1042 38.3975 20.335 38.9131 20.7086 39.2867C21.0822 39.6603 21.5987 39.8911 22.1683 39.8911C22.7388 39.8911 23.2544 39.6603 23.628 39.2867C24.0016 38.9131 24.2324 38.3966 24.2324 37.827C24.2324 37.2565 24.0016 36.7409 23.628 36.3673Z"
  />
  <path
   d="M24.2252 41.4998C25.2664 41.8652 26.1803 42.5008 26.8827 43.3204C27.5777 44.1308 28.0677 45.1253 28.271 46.2205C28.4037 46.933 28.3341 47.3561 28.0182 47.6628C27.7105 47.9614 27.3287 47.9998 26.7673 47.9998H17.4422V47.9971C16.9404 47.9962 16.5832 47.9476 16.2975 47.6537C16.0136 47.3616 15.9376 46.9605 16.0493 46.3048C16.2398 45.1921 16.727 44.1802 17.4257 43.3524C18.1318 42.5172 19.0567 41.8707 20.1125 41.4998L20.3918 41.4019L20.6473 41.553C20.8717 41.6848 21.1171 41.7892 21.3781 41.8606C21.629 41.9293 21.8946 41.966 22.1684 41.966C22.4422 41.966 22.7078 41.9293 22.9587 41.8606C23.2197 41.7892 23.4651 41.6848 23.6895 41.553L23.945 41.4019L24.2243 41.4998H24.2252ZM25.9129 44.15C25.4129 43.5667 24.7801 43.1015 24.0613 42.803C23.8167 42.9238 23.5594 43.0227 23.2939 43.0951C22.9349 43.1931 22.5576 43.2453 22.1684 43.2453C21.7792 43.2453 21.4019 43.1931 21.0429 43.0951C20.7765 43.0227 20.52 42.9247 20.2755 42.803C19.5457 43.1061 18.9047 43.5813 18.4001 44.1775C17.8461 44.8332 17.4596 45.6363 17.3085 46.5191C17.2691 46.6647 17.3232 46.7215 17.4413 46.7215L26.7664 46.7187C26.966 46.7187 27.0585 46.6784 27.0155 46.4495C26.8543 45.5832 26.4651 44.7947 25.912 44.1491L25.9129 44.15Z"
  />
  <path
   d="M32.0008 16C32.9239 16 33.76 16.3745 34.3653 16.9799C34.9706 17.5852 35.3451 18.4212 35.3451 19.3443C35.3451 20.2674 34.9706 21.1035 34.3653 21.7088C33.76 22.3141 32.9239 22.6886 32.0008 22.6886C31.0777 22.6886 30.2417 22.3141 29.6363 21.7088C29.031 21.1035 28.6565 20.2674 28.6565 19.3443C28.6565 18.4212 29.031 17.5852 29.6363 16.9799C30.2417 16.3745 31.0777 16 32.0008 16ZM33.4605 17.8846C33.0869 17.511 32.5704 17.2802 32.0008 17.2802C31.4303 17.2802 30.9147 17.511 30.5411 17.8846C30.1675 18.2582 29.9367 18.7747 29.9367 19.3443C29.9367 19.9148 30.1675 20.4304 30.5411 20.804C30.9147 21.1777 31.4312 21.4084 32.0008 21.4084C32.5713 21.4084 33.0869 21.1777 33.4605 20.804C33.8341 20.4304 34.0649 19.9139 34.0649 19.3443C34.0649 18.7738 33.8341 18.2582 33.4605 17.8846Z"
  />
  <path
   d="M34.0576 23.0174C35.0988 23.3828 36.0127 24.0183 36.7151 24.8379C37.4101 25.6484 37.9001 26.6429 38.1034 27.7381C38.2361 28.4506 38.1665 28.8736 37.8506 29.1804C37.5429 29.479 37.161 29.5174 36.5997 29.5174H27.2746V29.5147C26.7728 29.5138 26.4156 29.4652 26.1299 29.1713C25.846 28.8791 25.77 28.478 25.8817 27.8224C26.0722 26.7097 26.5594 25.6978 27.2581 24.87C27.9642 24.0348 28.8891 23.3883 29.9449 23.0174L30.2242 22.9194L30.4797 23.0705C30.7041 23.2024 30.9495 23.3068 31.2105 23.3782C31.4614 23.4469 31.727 23.4835 32.0008 23.4835C32.2746 23.4835 32.5402 23.4469 32.7911 23.3782C33.0521 23.3068 33.2975 23.2024 33.5219 23.0705L33.7773 22.9194L34.0567 23.0174H34.0576ZM35.7453 25.6676C35.2453 25.0843 34.6125 24.6191 33.8936 24.3205C33.6491 24.4414 33.3918 24.5403 33.1262 24.6127C32.7673 24.7106 32.39 24.7628 32.0008 24.7628C31.6116 24.7628 31.2343 24.7106 30.8753 24.6127C30.6089 24.5403 30.3524 24.4423 30.1079 24.3205C29.3781 24.6236 28.7371 25.0989 28.2325 25.6951C27.6784 26.3508 27.292 27.1539 27.1409 28.0367C27.1015 28.1823 27.1556 28.239 27.2737 28.239L36.5988 28.2363C36.7984 28.2363 36.8909 28.196 36.8479 27.9671C36.6867 27.1008 36.2975 26.3123 35.7444 25.6667L35.7453 25.6676Z"
  />
 </svg>
</template>

<!-- <style lang="scss">
svg {
 width: 64px;
 height: 64px;
}
</style> -->
