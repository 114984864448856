<template>
 <base-popup>
  <div class="form">
   <base-input
    label="Название"
    :value="this.createOrg.name"
    @data="this.createOrg.name = $event"
   />
   <base-input
    label="ИНН"
    :value="this.createOrg.inn"
    @data="this.createOrg.inn = $event"
   />
   <base-input
    label="КПП"
    :value="this.createOrg.kpp"
    @data="this.createOrg.kpp = $event"
   />
   <base-input
    label="Руководитель"
    :value="this.createOrg.head"
    @data="this.createOrg.head = $event"
   />
   <base-input
    label="Телефон"
    :value="this.createOrg.phone"
    @data="this.createOrg.phone = $event"
   />
   <base-input
    label="Регион"
    :value="this.createOrg.region"
    @data="this.createOrg.region = $event"
   />
   <base-input
    label="Город"
    :value="this.createOrg.city"
    @data="this.createOrg.city = $event"
   />
   <base-input
    label="Адрес"
    :value="this.createOrg.address"
    @data="this.createOrg.address = $event"
   />
   <base-input
    label="Тип"
    mode="select"
    :defaultOption="
     this.createOrg.orgtype
      ? this.convertOrgType(this.createOrg.orgtype, 2)
      : 'Не выбран'
    "
    @selected="this.createOrg.orgtype = this.convertOrgType($event, 1)"
    :options="['Без типа', 'Клиент', 'Сервисная', 'Проверяющий орган']"
   />
   <div class="btns">
    <base-button title="Отмена" mode="flat" @click="closePopup" />
    <base-button title="Создать" :mode="validCreateOrg" @click="create" />
   </div>
  </div>
 </base-popup>
</template>

<script>
export default {
 emits: ["close"],
 data() {
  return {
   createOrg: {
    organization_id: null,
    name: null,
    inn: null,
    kpp: null,
    head: null,
    phone: null,
    region: null,
    city: null,
    address: null,
    orgtype: null,
   },
  };
 },
 methods: {
  closePopup() {
   this.$emit("close");
  },
  create() {
   this.$store.dispatch("createOrg", this.createOrg);
   this.closePopup();
  },
  convertOrgType(val, direction) {
   if (this.types) {
    if (direction === 1) {
     const item = this.types.find((el) => el.name === val);
     if (item) {
      return item.identity;
     }
     return null;
    } else {
     const item = this.types.find((el) => el.identity === val);
     if (item) {
      return item.name;
     }
     return null;
    }
   }
  },
 },
 computed: {
  types() {
   return this.$store.getters.getOrgTypes;
  },
  validCreateOrg() {
   for (let el in this.createOrg) {
    if (this.createOrg[el] === null && el !== "organization_id") {
     return "disabled";
    }
   }
   return "full";
  },
 },
};
</script>

<style lang="scss" scoped>
.form {
 display: grid;
 grid-gap: 15px;
}
.btns {
 width: 100%;
 display: grid;
 justify-content: end;
 grid-gap: 15px;
 justify-items: center;
 grid-template-columns: repeat(2, 1fr);
}
</style>
