import { createStore } from "vuex";
import usersModule from "./usersModule";
import organizationModule from "./organizationModule";
import accountModule from "./accountModule";
export const BASE_URL = "https://sibest.erminesoft.ru";
export default createStore({
 modules: { usersModule, organizationModule, accountModule },
 state: {
  error: null,
  isLoading: false,
  roles: null,
  currentUser: null,
  modules: null,
  currentUserRights: ["listusers"],
 },
 getters: {
  getModules(state) {
   if (state.modules) {
    return state.modules;
   }
   return [];
  },
  getErrorMessage(state) {
   return state.error;
  },
  getLoadingState(state) {
   return state.isLoading;
  },
  getCurrnetUserRights(state) {
   return state.currentUserRights;
  },
  getCurrentUser(state) {
   return state.currentUser;
  },
  getRoles(state) {
   return state.roles;
  },
 },
 actions: {
  clearError(context) {
   context.commit("clearErrorMessage");
  },
  listModules(context) {
   fetch(`${BASE_URL}/api/user/listmodules`, {
    headers: {
     hash: localStorage.getItem("hash"),
    },
   })
    .then((response) => response.json())
    .then((data) => context.commit("listModules", data.payload));
  },
  logout(context) {
   fetch(`${BASE_URL}/api/user/logout`, {
    method: "GET",
    credentials: "include",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status !== "success") {
      return;
     } else {
      context.commit("logout");
      localStorage.removeItem("hash");
     }
    });
  },
  getUser(context) {
   fetch(`${BASE_URL}/api/user/getuser`, {
    method: "GET",
    credentials: "include",
    headers: {
     hash: localStorage.getItem("hash"),
     "Content-Type": "application/json",
    },
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "error") {
      return;
     } else {
      context.dispatch("listModules");
      context.dispatch("getRoles");
      context.dispatch("getOrgTypes");
      context.commit("setCurrentUser", data);
     }
    });
  },

  async login(context, payload) {
   fetch(`${BASE_URL}/api/user/login`, {
    method: "POST",
    headers: {
     hash: "",
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     email: payload.email,
     password: payload.password,
    }),
   })
    .then((response) => response.json())
    .then((data) => {
     if (data.status === "success") {
      context.commit("setCurrentUser", data.payload);
      context.dispatch("listModules");
      context.dispatch("getRoles");
      context.dispatch("getOrgTypes");
     } else {
      context.commit("setError", data.message);
     }
    })
    .catch((err) => context.commit("setError", err));
  },
 },
 mutations: {
  listModules(state, payload) {
   state.modules = payload;
  },
  clearErrorMessage(state) {
   state.error = null;
  },
  setError(state, payload) {
   state.error = payload;
  },
  logout(state) {
   state.currentUser = null;
  },

  setCurrenUserRights(state, payload) {
   state.roles = payload;
   const rights = [];
   const role = payload.find(
    (el) => el.userrole_id == state.currentUser.userrole_id
   );
   for (let el of role.userrights) {
    rights.push(el.identity);
   }
   state.currentUserRights = rights;
  },
  setCurrentUser(state, payload) {
   state.currentUser = payload;
   if (payload.hash) {
    localStorage.setItem("hash", payload.hash);
   }
  },
 },
});
