const PagesMixin = {
 methods: {
  setPerPage(e) {
   if (e <= this.list.length && e && e > 0) {
    this.localPagesData.perPage = e;
   } else {
    this.localPagesData.currentPage = 1;
    this.localPagesData.perPage = e;
   }
  },
  switchPage(direction) {
   if (this.localPagesData.currentPage >= 1) {
    if (
     direction === 1 &&
     this.localPagesData.currentPage < this.pagesData.totalPages
    ) {
     this.localPagesData.currentPage += 1;
    }

    if (direction === 2) {
     if (this.localPagesData.currentPage >= 2) {
      this.localPagesData.currentPage -= 1;
     }
    }
   }
  },
 },
};
export default PagesMixin;
