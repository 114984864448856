<template>
 <Teleport to="body">
  <base-popup v-if="deletePopupIsOpen">
   <div class="popup">
    <p>Действительно удалить?</p>

    <div class="btn-cancel">
     <base-button
      title="Отмена"
      mode="flat"
      @click="this.deletePopupIsOpen = false"
     />
    </div>
    <div class="btn-delete">
     <base-button
      class="btn-delete"
      title="Удалить"
      mode="delete"
      @click="deleteUser"
     />
    </div>
   </div>
  </base-popup>
 </Teleport>

 <base-popup
  v-if="detailsPopupIsOpen"
  :w="this.windowWidth > 600 ? '25%' : null"
 >
  <div>
   <div>
    <div v-for="el in detailsPopup" :key="el.head" class="details">
     <strong>{{ el.head }}:</strong>
     <div>{{ el.val }}</div>
    </div>
   </div>
   <div class="popup-actions">
    <base-button
     title="Закрыть"
     mode="flat"
     @click="this.detailsPopupIsOpen = false"
    />
    <base-button title="Изменить" mode="full" @click="edit" />
   </div>
  </div>
 </base-popup>

 <div class="card" @click.stop="this.detailsPopupIsOpen = true">
  <div class="card-content">
   <div v-for="el in items" :key="el.head">
    <strong>{{ el.head }}:</strong>
    <div>{{ el.val }}</div>
   </div>
  </div>
  <div class="btns" id="btns">
   <!-- <img src="../../assets/icons/icon-dots.svg" class="dots" /> -->
   <div class="icon">
    <img @click.stop="edit" src="../../assets/icons/icon-edit.svg" />
   </div>
   <div class="icon delete">
    <icon-delete @click.stop="this.deletePopupIsOpen = true" />
   </div>
  </div>
 </div>
</template>

<script>
import IconDelete from "../../assets/icons/icon-delete.vue";
export default {
 emits: ["edit", "delete"],
 components: { IconDelete },
 props: ["item", "displayItems", "gridColumns", "tableHead", "mode"],
 data() {
  return {
   windowWidth: null,
   deletePopupIsOpen: false,
   detailsPopupIsOpen: false,
  };
 },
 mounted() {
  this.windowWidth = window.innerWidth;
 },
 methods: {
  edit() {
   this.$emit("edit", this.item);
  },

  deleteUser() {
   this.$emit("delete", this.item);
   this.deletePopupIsOpen = false;
  },
 },

 computed: {
  detailsPopup() {
   const arr = [];

   for (let el of this.tableHead) {
    arr.push({
     head: el.title,
     val: this.item[el.field],
    });
   }
   return arr;
  },
  items() {
   const arr = [];
   let i = 0;
   for (let el of this.tableHead) {
    if (this.item[this.displayItems[i]]) {
     arr.push({
      head: el.title,
      val: this.item[this.displayItems[i]],
     });
    }
    i++;
   }
   return arr;
  },
  canEdit() {
   if (
    this.item.userrole_id === "Администратор" &&
    !this.userRights.includes("editadmin")
   ) {
    return false;
   }
   if (
    this.item.userrole_id === "Менеджер пользователей" &&
    !this.userRights.includes("editadmin")
   ) {
    return false;
   }
   return true;
  },
  list() {
   let localItem = [];
   localItem = [...this.item.list(this.displayItems)];

   if (this.roleString) {
    localItem.push(this.roleString.name);
   }

   return localItem;
  },

  roleString() {
   if (this.roles) {
    return this.roles.find((el) => el.userrole_id == this.item.userrole_id);
   }
   return 0;
  },
  roles() {
   return this.$store.getters.getRoles;
  },
  userRights() {
   return this.$store.getters.getCurrnetUserRights;
  },
 },
};
</script>

<style lang="scss" scoped>
@use "../../assets/variebles.scss" as var;
.popup-actions {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-gap: 15px;
 width: 100%;
 justify-items: center;
}
.details {
 margin-bottom: 20px;
}
.card {
 height: 100% !important;
 transition: 200ms;
 height: 35%;
 position: relative;
 padding: 15px;
 border-radius: 16px;
 background-color: white;
 background-clip: border-box;
 overflow: hidden;
 .card-content {
  height: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: repeat(3, 1fr);
  div:last-child {
   div {
    word-break: normal;
    width: 70%;
   }
  }
  div {
   div {
    word-break: normal;
    width: 95%;
   }
  }
 }
 .btns {
  transform: translateX(85%);
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  padding: 8px;
  border-radius: 16px 0px 16px 0px;
  background-color: var.$yellow;
  transition: 200ms;
  .dots {
   animation-fill-mode: backwards;
   transition: all 300ms;
  }
 }
 .card:hover .btns {
  width: fit-content;
  transition: 200ms;
  transform: translateX(0%);
  .dots {
   width: 0%;
  }
 }
}
.card:hover {
 cursor: pointer;
 transition: 200ms;
 transform: scale(1.05);
}

.head {
 margin-right: 10px;
 height: 100%;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
}
.list-item-container {
 display: grid;
 grid-template-columns: v-bind(gridColumns);
 align-items: center;
 justify-items: start;
}
.icon {
 cursor: pointer;
 transition: 200ms;
 padding: 5px;
 height: 34px;
 width: 34px;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 8px;
}
.icon:hover {
 transition: 200ms;
 background-color: #91979c96;
}
.delete.delete:hover {
 fill: var.$red;
}
.list-item {
 width: 80%;
}
.popup {
 display: grid;
 grid-gap: 38px;
 grid-template-areas:
  "title title"
  "cancel delete";
 font-weight: 600;
 .popup-buttons {
  width: 100%;
  display: inline;
  .btn-cancel {
   grid-area: cancel;
  }
  .btn-delete {
   grid-area: delete;
  }
 }
 p {
  grid-area: title;
 }
}
.actions {
 width: 100%;
 display: flex;
 justify-content: space-between;
}
@media screen and (max-width: 1280px) {
 .list-item {
  width: 80%;
  word-break: break-word;
 }
}
@media screen and (max-width: 600px) {
 .card:hover {
  transform: scale(1);
 }
 #btns {
  width: fit-content;
  transition: 200ms;
  transform: translateX(0%);
  .dots {
   display: none;
  }
 }
}
</style>
