<template>
 <div
  class="sidebar"
  v-if="this.windowWidth > 600"
  @mouseenter="setOpen"
  @mouseleave="setClosed"
 >
  <div class="logo">
   <img src="../../assets/icons/logo.svg" />
   <img v-if="isOpen" class="name" src="../../assets/icons/icon-name.svg" />
  </div>
  <div class="icons-container">
   <div class="icons">
    <div
     @click="switchPage"
     data-id="1"
     v-if="this.$store.getters.getModules.includes('users')"
    >
     <icon-users />
     <p :class="{ active: isOpen }">Пользователи</p>
    </div>

    <div
     @click="switchPage"
     data-id="2"
     v-if="this.$store.getters.getModules.includes('organizations')"
    >
     <icon-organizations />
     <p :class="{ active: isOpen }">Организации</p>
    </div>

    <div
     @click="switchPage"
     data-id="3"
     v-if="this.$store.getters.getModules.includes('types')"
    >
     <icon-books />
     <p :class="{ active: isOpen }">Справочники</p>
    </div>

    <div
     @click="switchPage"
     data-id="4"
     v-if="this.$store.getters.getModules.includes('devices')"
    >
     <icon-devices />
     <p :class="{ active: isOpen }">Аппараты</p>
    </div>

    <div
     @click="switchPage"
     data-id="5"
     v-if="this.$store.getters.getModules.includes('reports')"
    >
     <icon-reports />
     <p :class="{ active: isOpen }">Отчеты</p>
    </div>

    <div
     @click="switchPage"
     data-id="6"
     v-if="this.$store.getters.getModules.includes('notifications')"
    >
     <icon-notifications />
     <p :class="{ active: isOpen }">Уведомления</p>
    </div>

    <div @click="switchPage" data-id="7">
     <icon-account />
     <p :class="{ active: isOpen }">Учетная запись</p>
    </div>
   </div>
   <div class="divider">
    <div class="exit" @click="logout">
     <!-- <img src="../../assets/icons/exit.svg" /> -->
     <icon-exit />
     <p :class="{ active: isOpen }">Выход</p>
    </div>
   </div>
  </div>
 </div>
 <div class="sidebar-mobile" v-else>
  <div class="logo">
   <img src="../../assets/icons/logo.svg" />
   <img class="name" src="../../assets/icons/icon-name.svg" />
  </div>

  <img
   class="expand"
   src="../../assets/icons/arrow.svg"
   @click="switchOpen"
   :class="{ active: isOpen }"
  />
  <div class="menu" v-if="isOpen">
   <div class="icons" data-id="1" @click="switchPage">
    <icon-users />
    <p>Пользователи</p>
   </div>

   <div class="icons" data-id="2" @click="switchPage">
    <icon-organizations />
    <p>Организации</p>
   </div>

   <div class="icons">
    <icon-books />
    <p>Справочники</p>
   </div>

   <div class="icons">
    <icon-devices />
    <p>Аппараты</p>
   </div>

   <div class="icons">
    <icon-reports />
    <p>Отчеты</p>
   </div>

   <div class="icons" data-id="7" @click="switchPage">
    <icon-account />
    <p>Учетная запись</p>
   </div>
   <div @click="logout" class="icons">
    <icon-exit />
    <p>Выход</p>
   </div>
  </div>
 </div>
</template>

<script>
import iconUsers from "@/assets/icons/icon-users.vue";
import iconOrganizations from "@/assets/icons/icon-organizations.vue";
import iconBooks from "@/assets/icons/icon-books.vue";
import iconDevices from "@/assets/icons/icon-devices.vue";
import iconReports from "@/assets/icons/icon-reports.vue";
import iconNotifications from "@/assets/icons/icon-notifications.vue";
import iconAccount from "@/assets/icons/icon-account.vue";
import iconExit from "@/assets/icons/icon-exit.vue";
export default {
 emits: ["switchPage", "sidebarState"],
 components: {
  iconExit,
  iconAccount,
  iconNotifications,
  iconUsers,
  iconOrganizations,
  iconBooks,
  iconDevices,
  iconReports,
 },
 data() {
  return {
   windowWidth: null,
   isOpen: false,
  };
 },

 methods: {
  logout() {
   this.$store.dispatch("logout");
   this.$emit("switchPage", "");
   this.setClosed();
  },
  switchPage(event) {
   if (this.windowWidth < 460) {
    this.switchOpen();
   }
   let arr = document.querySelector(".icons");
   arr = Array.from(arr.children);
   for (let el of arr) {
    el.classList.remove("active");
   }
   event.currentTarget.classList.add("active");
   switch (event.currentTarget.dataset.id) {
    case "1":
     this.$emit("switchPage", "UsersList");
     break;
    case "2":
     this.$emit("switchPage", "OrganizationsList");
     break;
    case "7":
     this.$emit("switchPage", "UserAccount");
     break;
   }
  },
  switchOpen() {
   this.isOpen = !this.isOpen;
  },
  setClosed() {
   this.isOpen = false;
   this.$emit("sidebarState", this.isOpen);
  },
  setOpen() {
   this.isOpen = true;
   this.$emit("sidebarState", this.isOpen);
  },
 },
 computed: {
  currentUserRole() {
   return this.$store.getters.getCurrentUser.userrole_id;
  },
 },
 mounted() {
  this.windowWidth = window.innerWidth;
 },
};
</script>

<style scoped lang="scss">
@use "@/assets/variebles.scss" as var;
.sidebar-mobile {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 position: relative;
 //  border-radius: 16px 16px 0px;
 .expand {
  width: 8%;
  transform: rotate(90deg);
 }
 .expand.active {
  transform: rotate(-90deg);
 }
 .menu {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  background-color: var.$white;
  width: 100%;
  top: 100%;
  position: absolute;
  .icons {
   display: grid;
   grid-template-columns: 1fr 2fr;
   align-items: center;
   width: 50%;
   //  display: flex;
   //  align-items: center;
   //  justify-content: center;
  }
 }
 background-color: var.$white;
 .logo {
  display: flex;
  justify-content: center;
 }
}
.divider {
 padding-top: 10px;
 border-top: 2px solid var.$light;
 margin: 0px 12px;
}

.sidebar {
 overflow: hidden;
 display: flex;
 flex-direction: column;
 border-radius: 16px 16px 0px 0px;
 grid-area: sideBar;
 background-color: var.$white;
 height: auto;
 width: 100%;
 .logo {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var.$light;
  padding-bottom: 9px;
  margin: 0px 9px 32px;
 }
 .icons-container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
 }

 .icons {
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  div {
   width: fit-content;
   color: var.$black;
   display: flex;
   align-items: center;
   border-radius: 8px;
   padding: 0px 86px 0px 0px;
   p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
    transition: 100ms;
    transform: translateX(50px);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
   }
   p.active {
    transform: translateX(0);
   }
  }
  div:hover {
   cursor: pointer;
   width: 100%;
   transition: background-color 100ms;
   color: var.$white;
   background-color: var.$brand;
   fill: white;
  }
  div.active {
   width: 100%;
   background-color: var.$green;
   fill: white;
   color: var.$white;
  }
 }
 .exit {
  border-radius: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 8px;
  margin: 0px 0px 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  p {
   font-family: "Montserrat", sans-serif;
   font-weight: 600;
   font-size: 16px;
   transition: 100ms;
   transform: translateX(50px);
   text-overflow: ellipsis;
   white-space: nowrap;
   margin-left: 8px;
  }
  p.active {
   transform: translateX(0);
  }
 }
 .exit:hover {
  transition: background-color 100ms;
  fill: white;
  cursor: pointer;
  color: var.$white;
  background-color: var.$brand;
 }
}
</style>
